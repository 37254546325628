*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.shopping{
    width: 80%;
    height: 100hv;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 1px 1px 3px rgb(220, 220, 220);
    margin: auto;
    background-color: rgb(246, 246, 246);
    padding-bottom: 40px;
}
.springer-ecom-logo{
    width: 50%;
    padding: 20px;
}
img{
    border: 0;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.search-and-header-cart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
}
.search-container{
display: flex;
padding: 20px;
justify-content: center;
align-items: center;
width: 60%;
height: 100%;
}
.search-input{
flex-basis: 95%;
width: 100%;
font-size: 12px;
color: gray;
padding: 9px;
}
.search-input:focus{
    border: 1px thin gray;
}
.search-icon{
    flex-basis: 5%;
    width: 50px;
    height: 36px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}
.header-cart{
    border: 1px gray;
    padding-right: 20px;
    background-color: rgb(235, 240, 235);
    padding: 10px;
    border-radius: 30%;
    margin-right: 20px;
}
.shopping-bottom{
width: 100%;
}
.navigation{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
    padding: 20px;
}
.home,li{
    font-size: 12px;
    font-weight: lighter;
    color: gray;
}
.home,.link{
    text-decoration: none;
}
.shopping-header{
    background-image: url('images/header_image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
}
.shopping-header-image{
    width: 100%;
}
.overlay{
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 70%;
    background-color: rgb(4, 1, 37);
    opacity: 0.5;
}
.text-overlay{
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 70%;
    padding: 30px;
}
.overlay-h1,.overlay-li{
    color: white;
    font-weight: lighter;
}
.shopping-down{
    width: 80%;
    margin: auto;
}
.paragraph{
    padding: 10px 0px 20px;
    text-align: justify;
    font-weight: lighter;
    font-size: 14px;
}
.shopping-section-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
}
.our-desciplines{
padding: 20px;
display: flex;
justify-content: center;
flex-direction: column;
box-shadow: 1px 1px 1px 3px rgb(220, 220,220);
}
.desciplines-title{
text-align: left;
font-size: 14px;
font-weight: lighter;
}
.descipline-list{
    margin-top: 0;
    margin-bottom: .45em;
    position: relative;
    color: rgb(0, 162, 255);
}
.recommendations-title{
    font-weight: lighter;
    font-size: 16px;
    padding: 20px 0px 20px;
}

.recommendations{
display: flex;
justify-content: center;
gap: 20px;
align-items: center;
padding: 20px;
}
.item-container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    width: 300px;
    height: fit-content;
}
.item-image{
    flex-basis: 50%;
    width: 100%;
}
.item-text-wrapper{
    display: flex;
    align-items: baseline;
    flex-direction: column;
    flex-basis: 50%;
    width: 100%;
    gap: 0px;
}
.book-title{
    color: rgb(0, 162, 255);
    font-size: 12px;
}
.publication-year,.book-author,.book-range{
    font-size: 12px;
    color: gray;
}
.book-cost{
    font-weight: bold;
}
.more-titles{
    color: rgb(0, 162, 255);
}
.payment-gateways{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 20px;
}
.payment-gateways-images{
flex-basis: 35%;
 width: 100%;
}
.payment-gateways-text{
    flex-basis: 65%;
    width: 100%;
}
.trust-section{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}
.trust-image{
    flex-basis: 30%;
    width:200px;
}
.info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
}
.info-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-basis: 30%;
    width: 100%;
    /* padding: 30px; */
}
.info-icon{
    color: rgb(15, 113, 122);
    font-size: 400px;
}
.info-text{
    color: rgb(84, 81, 81);
    font-weight: lighter;
    padding: 30px;
}
.right-arrow{
    color: gray;
    font-size: 12px;
    margin: 5px;
    font-weight: lighter;
}
.shopping-footer{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: rgba(245, 239, 239, 0.817);
    width: 100%;
    padding: 30px;
    margin-top: 30px;
}
.content-title{
    color: rgb(72, 69, 69);
}
.content-item{
    list-style: none;
}
.footer-title{
text-align: left;
font-weight: bold;
color: gray;
font-size: 16px;
}
.footer-text{
text-align: left;
font-weight: lighter;
color: gray;
font-size: 12px;
}
.bottom-footer{
align-self: flex-start;
padding: 20px;
}
.watermark{
    width: 100%;
}