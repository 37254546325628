*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
link:hover{
  /* text-decoration: 1px solid underline; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --footer-bg: rgb(5, 47, 72);
  --primary-color: #34495E;
  /* --primary-color: #00008B; */
  /* --primary-color: #003153; */
  --secondary-color: rgb(9, 13, 127);
  --dark-blue-color: rgb(13, 79, 108);
  --pale-blue-color: rgb(25, 78, 100);
  --dark-green-color: rgb(14, 105, 19);
  --lemon-color: rgb(132, 178, 59);
}