.term-container{
    margin-top: 40px;
    padding: 20px;
}
.term-item{
    margin-top: 25px !important;
}
.term-title{
    font-size: 16px;
    margin-bottom: 10px;
}
.term-content{
    font-size: 14px;
    font-weight: light;
    text-align: justify;
}