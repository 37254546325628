@charset "UTF-8";
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset-6f3a494377.woff2)
format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset-6f3a494377.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-cyrillic-subset-c3ebcc0815.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-cyrillic-subset-c3ebcc0815.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-vietnamese-subset-6dcd26c0e5.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-vietnamese-subset-6dcd26c0e5.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-latin-ext-subset-120ebf2685.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-latin-ext-subset-120ebf2685.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-latin-subset-6dc4b01905.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherItalic-Variable-Optimised-latin-subset-6dc4b01905.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset-a832f5ed21.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset-a832f5ed21.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset-a832f5ed21.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset-81b7d49566.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset-81b7d49566.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset-81b7d49566.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-subset-c60161cc6f.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-subset-c60161cc6f.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSansItalic-Variable-Optimised-latin-subset-c60161cc6f.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-cyrillic-ext-subset-3494a74b56.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-cyrillic-ext-subset-3494a74b56.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-cyrillic-subset-e71cd121e7.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-cyrillic-subset-e71cd121e7.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-vietnamese-subset-7d74246d3d.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-vietnamese-subset-7d74246d3d.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-latin-ext-subset-91acea99c4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-latin-ext-subset-91acea99c4.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-latin-subset-af88e29d70.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Merriweather-Variable-Optimised-latin-subset-af88e29d70.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-vietnamese-subset-3a93430ddb.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-vietnamese-subset-3a93430ddb.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-vietnamese-subset-3a93430ddb.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-ext-subset-6355dd6b32.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-ext-subset-6355dd6b32.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-ext-subset-6355dd6b32.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-subset-6a2bbc1ea4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-subset-6a2bbc1ea4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/MerriweatherSans-Variable-Optimised-latin-subset-6a2bbc1ea4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  line-height: 1.8;
  min-height: 100%;
  color: #222;
  background: #fff;
}
a {
  color: #025e8d;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
button {
  cursor: pointer;
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
html {
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
}
h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
}
h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
}
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  font-weight: 700;
}
h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}
h4,
h5 {
  font-size: 1.25rem;
}
body,
h5 {
  font-size: 1.125rem;
}
*,
:after,
:before {
  box-sizing: inherit;
}
abbr[title] {
  text-decoration: none;
}
dd {
  margin: 0;
}
p {
  margin-top: 0;
  margin-bottom: 2rem;
}
p:last-of-type {
  margin-bottom: 0;
}
a.visited,
a:visited {
  color: #01324b;
}
a.hover,
a:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
a.active,
a:active {
  color: #025e8d;
}
a.focus,
a:focus {
  outline: 4px solid #08c;
}
table {
  font-size: 1rem;
  line-height: 1.8;
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin-bottom: 32px;
}
td,
th {
  padding: 8px;
  vertical-align: top;
}
th {
  text-align: left;
  border-bottom: 3px solid #01324b;
}
td {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  border-bottom: 2px solid #dadada;
}
[contenteditable]:focus,
button:not([disabled]):focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus {
  outline: 4px solid #08c;
}
input[type="file"]:focus-within {
  outline: 4px solid #08c;
}
.l-grid {
  list-style: none;
  padding: 0;
}
.l-grid > * + * {
  margin-top: 1em;
  margin-top: var(--grid--gap, 1em);
}
@supports (display: grid) {
  .l-grid > * {
    margin: 0;
  }
  .l-grid {
    display: grid;
    gap: 1em;
    gap: var(--grid--gap, 1em);
    grid-template-columns: repeat(
      auto-fit,
      minmax(var(--grid--basis, min(300px, 100%)), 1fr)
    );
  }
}
.eds-c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  background-color: #fff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 1rem;
  line-height: 1.5;
}
.eds-c-header__container {
  max-width: 1280px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}
.eds-c-header__nav {
  border-top: 2px solid #cedbe0;
  padding-top: 4px;
  position: relative;
}
.eds-c-header__nav-container {
  max-width: 1280px;
  padding: 0 8px;
  margin: 0 auto 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.eds-c-header__nav-container > :not(:last-child) {
  margin-right: 32px;
}
.eds-c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}
.eds-c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.eds-c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  font-weight: 700;
  padding: 8px;
}
.eds-c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #cedbe0;
}
.eds-c-header__item {
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .eds-c-header__item--menu:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
}
.eds-c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.eds-c-header__item--inline-links:first-child + * {
  -webkit-margin-before: 0;
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}
.eds-c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #cedbe0;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}
.eds-c-header__brand {
  padding: 16px 8px;
}
.eds-c-header__brand a {
  display: block;
  line-height: 1;
  text-decoration: none;
}
.eds-c-header__brand img {
  height: 1.5rem;
  width: auto;
}
.eds-c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: 700;
  -webkit-text-decoration-color: transparent;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.eds-c-header__link:visited {
  color: inherit;
}
.eds-c-header__link:hover {
  color: inherit;
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover {
  -webkit-text-decoration-color: transparent;
  text-decoration-color: transparent;
}
.eds-c-header__link:not(
    .eds-c-header__expander .eds-c-header__link
  ):hover::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}
.eds-c-header__link--static {
  flex: 0 0 auto;
}
.eds-c-header__link.is-open {
  color: #025e8d;
}
.eds-c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}
.eds-c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 1.5rem;
}
.eds-c-header__icon + * {
  margin-left: 8px;
}
.eds-c-header__expander {
  background-color: #f0f7fc;
}
.eds-c-header__search {
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__search {
    max-width: 70%;
  }
}
.eds-c-header__search-container {
  position: relative;
}
.eds-c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: 700;
  margin-bottom: 8px;
}
.eds-c-header__search-input {
  border: 1px solid #000;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: #fff;
}
.eds-c-header__search-button {
  background-color: transparent;
  border: 0;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}
.has-tethered.eds-c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}
@media only screen and (min-width: 768px) {
  .has-tethered.eds-c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.eds-c-header__expander--menu:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
}
.has-tethered.eds-c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}
.has-tethered .eds-c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .eds-c-header__heading:first-child + * {
  -webkit-margin-before: 0;
  margin-block-start: 0;
}
.has-tethered .eds-c-header__search {
  margin: auto;
}
.eds-c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 16px 16px 0;
}
.eds-c-footer {
  font-size: 1rem;
  background-color: #01324b;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
  line-height: 1.4;
}
.eds-c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .eds-c-footer__container {
    display: none;
  }
}
.eds-c-footer__links {
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
}
.c-footer__links li,
.eds-c-footer__links li {
  display: inline-block;
}
.c-footer__links li:not(:last-child),
.eds-c-footer__links li:not(:last-child) {
  margin-right: 24px;
}
.eds-c-footer__user {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 24px;
}
.eds-c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}
.eds-c-footer__legal {
  color: #fff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}
.eds-c-footer__link {
  color: inherit;
  line-height: 1.4;
}
.eds-c-footer__link.hover,
.eds-c-footer__link.visited,
.eds-c-footer__link:hover,
.eds-c-footer__link:visited {
  color: inherit;
}
.eds-c-footer__link > img {
  vertical-align: middle;
}
button.eds-c-footer__link {
  border: 0;
  background: 0 0;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: inherit;
  padding: 0;
  text-align: left;
}
.eds-c-footer__button-text {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
  color: inherit;
}
.eds-c-footer__button-text.hover,
.eds-c-footer__button-text:hover {
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.active,
.eds-c-footer__button-text:active {
  color: #025e8d;
}
.eds-c-footer__button-text.focus,
.eds-c-footer__button-text:focus {
  outline: 4px solid #08c;
}
.eds-c-footer__button-text.hover,
.eds-c-footer__button-text.visited,
.eds-c-footer__button-text:hover,
.eds-c-footer__button-text:visited {
  color: inherit;
}
.eds-c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.eds-c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .eds-c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}
.eds-c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .eds-c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .eds-c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}
.eds-c-footer__group--separator {
  border-bottom: 2px solid #fff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}
.eds-c-footer__heading {
  color: #fff;
  margin-bottom: 16px;
  font-weight: 700;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.eds-c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.eds-c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}
.c-card-collection {
  position: relative;
  display: flex;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  background-color: #fff;
  padding: 16px;
  border: 1px solid #cedbe0;
  border-top: 10px solid #3c9cd7;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}
.c-card-collection__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  border-bottom: 1px solid #cedbe0;
  padding-bottom: 16px;
}
.c-card-collection__side {
  padding-top: 16px;
}
.c-card-collection__content {
  font-weight: 400;
}
.c-card-collection__text {
  line-height: 1.7;
}
.c-card-collection__text p,
.c-card-highlight__text p,
.c-card-highlight__text ul {
  margin: 0;
}
.c-card-collection__heading {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.25rem;
}
.c-card-collection__heading a {
  color: #222;
  line-height: 1.5;
}
.c-card-highlight__heading a {
  color: #222;
}
.c-card-collection__heading a::before,
.c-card-open__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.c-card-collection__description-list {
  font-size: 0.875rem;
  margin: 0 0 16px;
}
.c-card-collection__description-list:last-of-type {
  margin-bottom: 0;
}
.c-card-collection__description-list dd {
  font-weight: 700;
  margin: 0;
}
.c-card-collection__status-message {
  margin-top: 16px;
}
.c-card-collection__status-message svg {
  fill: #025e8d;
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .c-card-collection {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: 1px solid #cedbe0;
  }
  .c-card-collection--stack {
    flex-direction: column;
    border-left: 1px solid #cedbe0;
    border-top: 10px solid #3c9cd7;
  }
  .c-card-collection__main {
    padding-right: 16px;
    border-right: 1px solid #cedbe0;
    border-bottom: none;
    padding-bottom: 0;
  }
  .c-card-collection__side {
    min-width: 240px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 0;
  }
}
.c-card-highlight {
  display: flex;
  background-color: #f0f7fc;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.c-card-highlight__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
}
.c-card-highlight__content {
  font-weight: 400;
}
.c-card-highlight__text {
  line-height: 1.7;
}
.c-card-highlight__text + .c-card-highlight__cta {
  display: inline-block;
  margin-top: 8px;
}
.c-card-highlight__cta {
  font-weight: 700;
  color: #025e8d;
}
.c-card-highlight__cta:focus,
.c-card-highlight__cta:hover {
  color: #025e8d;
}
.c-card-highlight__cta-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.c-card-highlight__heading {
  margin-top: 0;
}
.c-card-highlight .c-card-highlight__side img {
  width: 100%;
  height: auto;
}
.c-card-highlight .c-card-highlight__text img {
  width: auto;
  max-width: 100%;
}
.c-card-highlight .c-card-highlight__text img.float--right {
  float: left;
}
.c-card-highlight__icon {
  fill: #025e8d;
  width: 72px;
  height: 72px;
}
@media only screen and (min-width: 480px) {
  .c-card-highlight {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: none;
  }
  .c-card-highlight__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .c-card-highlight .c-card-highlight__side {
    flex: 0 0 auto;
    align-self: center;
    margin: -16px -16px -16px 16px;
  }
  .c-card-highlight__heading,
  .c-card-highlight__side {
    margin-bottom: 0;
  }
  .c-card-highlight .c-card-highlight__side img {
    border-radius: 0 12px 12px 0;
    width: 256px;
    height: 144px;
    aspect-ratio: 16/9;
  }
  .c-card-highlight .c-card-highlight__text img {
    max-width: 40%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-card-highlight--stack {
    flex-direction: column-reverse;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .c-card-highlight--stack .c-card-highlight__side img {
    border-radius: initial;
  }
  .c-card-highlight__content {
    display: block;
    flex-direction: unset;
    justify-content: unset;
  }
  .c-card-highlight__heading {
    margin-bottom: 8px;
  }
  .c-card-highlight--stack .c-card-highlight__side {
    max-width: none;
    margin: -16px -16px 0;
  }
  .c-card-highlight--stack .c-card-highlight__heading {
    margin-top: 16px;
  }
}
.c-card-open {
  position: relative;
  border-bottom: 1px solid transparent;
  background-color: #fff;
  padding: 24px 0;
  border-top: 1px solid #cedbe0;
}
.c-card-open a:not(.c-card-open__link) {
  position: relative;
}
.c-card-open--last {
  border-bottom: 1px solid #cedbe0;
}
@media only screen and (min-width: 480px) {
  .c-card-open {
    display: flex;
    justify-content: space-between;
  }
}
.c-card-open__main {
  flex: 1 1 auto;
  word-break: break-word;
}
@media only screen and (min-width: 480px) {
  .c-card-open__main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 192px;
  }
  .c-card-open--has-image .c-card-open__main {
    margin-right: 16px;
  }
}
.c-card-open__side {
  position: absolute;
  right: 0;
  top: 24px;
  overflow: hidden;
}
.c-card-open--portrait-ratio .c-card-open__side {
  overflow: auto;
}
@media only screen and (min-width: 480px) {
  .c-card-open__side {
    position: initial;
    flex: 0 0 auto;
    overflow: hidden;
  }
}
.c-card-open img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
  width: 112px;
  aspect-ratio: 16/9;
}
@media only screen and (min-width: 480px) {
  .c-card-open img {
    width: 192px;
  }
  .c-card-open--compact img {
    width: 112px;
  }
}
.c-card-open.c-card-open--portrait-ratio img {
  border-radius: 0;
  width: 88px;
  aspect-ratio: auto;
}
@media only screen and (min-width: 1024px) {
  .c-card-open.c-card-open--portrait-ratio img {
    width: 112px;
  }
}
.c-card-open__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
}
.c-card-open__heading a {
  line-height: 1.5;
  color: #222;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.c-card-open--has-image .c-card-open__heading {
  max-width: calc(100vw - 112px - 16px - 16px - 16px);
  min-height: 58px;
}
.c-card-open__collections-list {
  margin-top: 8px;
}
@media only screen and (min-width: 480px) {
  .c-card-open__heading {
    font-size: 1.125rem;
  }
  .c-card-open--has-image .c-card-open__heading {
    max-width: initial;
    min-height: initial;
  }
}
.c-card-open__meta {
  margin-top: 2px;
}
.c-card-open__authors {
  display: none;
  line-height: 1.5;
}
@media only screen and (min-width: 480px) {
  .c-card-open__authors {
    display: block;
  }
}
.c-card-open--compact.c-card-open {
  padding: 16px 0;
}
.c-card-open--compact .c-card-open__main,
.c-card-open--full-width .c-card-open__main {
  margin-right: 0;
}
.c-card-open--compact .c-card-open__heading {
  margin-bottom: 8px;
  font-size: 1rem;
}
.c-footer,
button.c-footer__link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.c-footer {
  font-size: 1rem;
  background-color: #01324b;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
  line-height: 1.15;
}
.c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .c-footer__container {
    display: none;
  }
}
.c-footer__links {
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
}
.c-footer__user {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 24px;
}
.c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}
.c-footer__legal {
  color: #fff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}
.c-footer__link {
  color: inherit;
  line-height: 1.4;
}
.c-footer__link.hover,
.c-footer__link.visited,
.c-footer__link:hover,
.c-footer__link:visited {
  color: inherit;
}
.c-footer__link > img {
  vertical-align: middle;
}
button.c-footer__link {
  border: 0;
  background: 0 0;
  padding: 0;
  text-align: left;
}
.c-footer__button-text {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
  color: inherit;
}
.c-footer__button-text.hover,
.c-footer__button-text:hover {
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.c-footer__button-text.active,
.c-footer__button-text:active {
  color: #025e8d;
}
.c-footer__button-text.focus,
.c-footer__button-text:focus {
  outline: 4px solid #08c;
}
.c-footer__button-text.hover,
.c-footer__button-text.visited,
.c-footer__button-text:hover,
.c-footer__button-text:visited {
  color: inherit;
}
.c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}
.c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}
.c-footer__group--separator {
  border-bottom: 2px solid #fff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}
.c-footer__heading {
  color: #fff;
  margin-bottom: 16px;
  font-weight: 700;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}
.c-masthead__colour-1 {
  --background-color: rgb(255, 21, 0);
}
.c-masthead__colour-2 {
  --background-color: rgb(255, 64, 0);
}
.c-masthead__colour-3 {
  --background-color: rgb(255, 106, 0);
}
.c-masthead__colour-4 {
  --background-color: rgb(255, 149, 0);
}
.c-masthead__colour-4,
.c-masthead__colour-5,
.c-masthead__colour-6 {
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}
.c-masthead__colour-5 {
  --background-color: rgb(255, 191, 0);
}
.c-masthead__colour-6 {
  --background-color: rgb(255, 234, 0);
}
.c-masthead__colour-7 {
  --background-color: rgb(234, 255, 0);
}
.c-masthead__colour-7,
.c-masthead__colour-8,
.c-masthead__colour-9 {
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}
.c-masthead__colour-8 {
  --background-color: rgb(191, 255, 0);
}
.c-masthead__colour-9 {
  --background-color: rgb(149, 255, 0);
}
.c-masthead__colour-10 {
  --background-color: rgb(106, 255, 0);
}
.c-masthead__colour-10,
.c-masthead__colour-11,
.c-masthead__colour-12 {
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}
.c-masthead__colour-11 {
  --background-color: rgb(43, 255, 0);
}
.c-masthead__colour-12 {
  --background-color: rgb(0, 255, 43);
}
.c-masthead__colour-13 {
  --background-color: rgb(0, 255, 106);
}
.c-masthead__colour-13,
.c-masthead__colour-14,
.c-masthead__colour-15 {
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}
.c-masthead__colour-14 {
  --background-color: rgb(0, 255, 149);
}
.c-masthead__colour-15 {
  --background-color: rgb(0, 255, 191);
}
.c-masthead__colour-16 {
  --background-color: rgb(0, 255, 234);
}
.c-masthead__colour-16,
.c-masthead__colour-17,
.c-masthead__colour-18 {
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}
.c-masthead__colour-17 {
  --background-color: rgb(0, 234, 255);
}
.c-masthead__colour-18 {
  --background-color: rgb(0, 191, 255);
}
.c-masthead__colour-19 {
  --background-color: rgb(0, 149, 255);
}
.c-masthead__colour-20 {
  --background-color: rgb(0, 106, 255);
}
.c-masthead__colour-21 {
  --background-color: rgb(0, 21, 255);
}
.c-masthead__colour-22 {
  --background-color: rgb(121, 77, 255);
}
.c-masthead__colour-23 {
  --background-color: rgb(151, 77, 255);
}
.c-masthead__colour-24 {
  --background-color: rgb(170, 51, 255);
}
.c-masthead__colour-25 {
  --background-color: rgb(198, 26, 255);
}
.c-masthead__colour-26 {
  --background-color: rgb(255, 0, 234);
}
.c-masthead__colour-27 {
  --background-color: rgb(255, 0, 191);
}
.c-masthead__colour-28 {
  --background-color: rgb(255, 0, 149);
}
.c-masthead__colour-29 {
  --background-color: rgb(255, 0, 106);
}
.c-masthead__colour-30 {
  --background-color: rgb(255, 0, 64);
}
.c-masthead__colour-31 {
  --background-color: rgb(255, 0, 21);
}
.c-masthead {
  position: relative;
  background: #0070a8;
  background: var(--background-color, #0070a8);
}
.c-masthead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    var(--gradient-light, rgba(0, 0, 0, 0.4)),
    var(--gradient-dark, rgba(0, 0, 0, 0.7))
  );
}
.c-masthead.c-masthead--disable-journal-colour::after {
  background: unset;
}
@media only screen and (max-width: 479px) {
  .c-masthead::after {
    background: linear-gradient(
      225deg,
      var(--gradient-light, rgba(0, 0, 0, 0.4)),
      var(--gradient-dark, rgba(0, 0, 0, 0.7))
    );
  }
}
.c-masthead__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  color: #fff;
}
.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 0.875rem;
  color: #222;
  line-height: 1.5;
}
.c-meta--large {
  font-size: 1.25rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 20px;
}
.c-meta__item {
  display: inline-block;
}
.c-meta__item:not(:last-child) {
  border-right: 1px solid #999;
  padding-right: 8px;
  margin-right: 8px;
}
.app-homepage-card__description-list dd,
.c-meta__item--bold {
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}
.c-meta__type {
  text-transform: none;
  font-weight: 700;
}
.c-meta__icon {
  width: 12px;
  height: 16px;
  fill: none;
  stroke: currentColor;
  vertical-align: middle;
  margin-right: 4px;
}
.c-meta__link {
  color: #222;
  text-decoration: underline;
}
.c-meta__link:visited {
  color: #222;
}
.c-skip-link {
  background: #01324b;
  color: #fff;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:active,
.c-skip-link:hover,
.c-skip-link:link,
.c-skip-link:visited {
  color: #fff;
}
.c-skip-link:focus {
  transform: translateY(0);
}
@media only screen and (min-width: 768px) {
  .app-homepage-about {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 64px;
    column-gap: 64px;
  }
}
.app-homepage-about > p {
  font-size: 1rem;
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .app-homepage-about > p {
    margin-bottom: 0;
  }
}
.app-homepage-brands {
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 32px;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-brands {
    margin-bottom: 64px;
  }
}
.app-homepage-brands__heading {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}
.app-homepage-brands__list {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  border-top: 1px solid #cedbe0;
  border-bottom: 1px solid #cedbe0;
  padding: 24px 0;
}
.app-homepage-brands__list li {
  flex: 1 1 50%;
  margin: 0 0 48px;
}
.app-homepage-brands__list li:not(:last-child) {
  padding-right: 24px;
}
.app-homepage-brands__list li:nth-last-child(1),
.app-homepage-brands__list li:nth-last-child(2) {
  margin: 0;
}
@media only screen and (min-width: 480px) {
  .app-homepage-brands__list li {
    flex: 1 1 33%;
  }
  .app-homepage-brands__list li:nth-last-child(3) {
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-brands__list {
    flex-wrap: wrap;
    text-align: left;
  }
  .app-homepage-brands__list li {
    flex: none;
    margin: 0;
  }
}
.app-homepage-card {
  display: flex;
  position: relative;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #cedbe0;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.app-homepage-card--stack {
  flex-direction: column;
}
.app-homepage-card--mutant {
  flex: 1 1 auto;
  flex-direction: row-reverse;
  border: 0;
  border-top: 1px solid #cedbe0;
  padding: 24px 0 8px;
  margin: 0;
  border-radius: 0;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant {
    flex-direction: column;
    padding: 16px;
    border: 1px solid #cedbe0;
    border-radius: 8px;
    flex: 1 1 262px;
  }
}
.app-homepage-card--mutant .app-homepage-card__main {
  margin-right: 16px;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__main {
    margin-right: 0;
  }
}
.app-homepage-card--mutant .app-homepage-card__text > div {
  margin-bottom: 4px;
  line-height: 1.875;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__text > div {
    margin-bottom: 16px;
    line-height: 1.7;
  }
}
.app-homepage-card--mutant .app-homepage-card__side {
  flex: 0 0 auto;
  max-width: 114px;
  width: 100%;
}
.app-homepage-card--mutant .app-homepage-card__side img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    border: 0;
    max-width: none;
    width: auto;
  }
}
@media (min-width: 640px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    max-width: 192px;
  }
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    max-width: none;
  }
}
.app-homepage-card--mutant .app-homepage-card__side--flush,
.app-homepage-card__text p,
.app-homepage-card__text ul {
  margin: 0;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side--flush {
    margin: -16px -16px 16px;
  }
}
.app-homepage-card__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
}
.app-homepage-card__side {
  flex: 0 1 auto;
}
.app-homepage-card__side--flush {
  margin: -16px -16px 16px;
}
.app-homepage-card__content {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}
.app-homepage-card__content--row {
  flex-direction: row;
}
.app-homepage-card__content--row > :first-child {
  align-self: center;
  padding-right: 16px;
  text-decoration: none;
  line-height: 1.5;
}
.app-homepage-card__content--row > :first-child:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.app-homepage-card__content--padding {
  padding: 16px 0;
}
.app-homepage-card__title-link {
  line-height: 1.4;
  color: currentColor;
}
.app-homepage-card__link--faux::before,
.app-homepage-card__title-link::before,
.app-homepage-featured-books__link::before,
.c-card-highlight__heading a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.app-homepage-card__title-link:hover,
.app-homepage-card__title-link:visited {
  color: currentColor;
}
.app-homepage-card__text {
  line-height: 1.7;
  position: relative;
}
.app-homepage-card__text > div:first-child {
  margin-bottom: 4px;
}
.app-homepage-card__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.125rem;
}
.app-homepage-card__heading a {
  color: #222;
  line-height: 1.5;
}
.app-homepage-card img {
  width: 100%;
}
.app-homepage-card__cover-image {
  flex: 0 0 auto;
}
.app-homepage-card__cover-image img {
  border: 1px solid #fff;
  max-width: 73px;
}
.app-homepage-card__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: #eff6fb;
  border-radius: 50%;
}
.app-homepage-card__icon-container img {
  width: 60%;
}
.app-homepage-card__icon-container + * {
  padding-left: 16px;
}
@media only screen and (min-width: 480px) {
  .app-homepage-card__icon-container {
    width: 62px;
    height: 62px;
  }
  .app-homepage-card__icon-container img {
    width: auto;
  }
}
.app-homepage-card__description-list {
  padding-top: 16px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}
.app-homepage-card__description-list > div {
  display: flex;
  justify-content: space-between;
}
.app-homepage-card__description-list > div:not(:last-child) {
  border-bottom: 1px solid #cedbe0;
  margin-bottom: 8px;
  padding-bottom: 8px;
}
.app-homepage-card__link {
  color: inherit;
  font-weight: 700;
}
.app-homepage-card__link.hover,
.app-homepage-card__link.visited,
.app-homepage-card__link:hover,
.app-homepage-card__link:visited {
  color: inherit;
}
@media only screen and (min-width: 480px) {
  .app-homepage-card__content {
    flex-direction: column;
    justify-content: space-between;
  }
  .app-homepage-card__content--row {
    flex-direction: row;
    justify-content: flex-start;
  }
  .app-homepage-card__content--row > :first-child {
    align-self: flex-start;
  }
  .app-homepage-card__text--flex {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .app-homepage-card__description {
    flex-grow: 1;
  }
}
.c-card-highlight {
  flex-direction: row;
  border-left: 10px solid #3c9cd7;
  border-top: none;
  padding: 24px 8px;
  position: relative;
}
.c-card-highlight__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.c-card-highlight .c-card-highlight__side {
  flex: 0 0 auto;
  align-self: center;
  margin: -24px -8px -24px 8px;
  max-width: 55%;
}
@media only screen and (min-width: 480px) {
  .c-card-highlight .c-card-highlight__side {
    max-width: 30%;
  }
}
.c-card-highlight__heading {
  margin-bottom: 0;
  font-size: 1rem;
}
.c-card-highlight__heading a {
  text-decoration: none;
  line-height: 1.2;
}
.c-card-highlight__heading a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.c-card-highlight__heading--secondary {
  font-size: 0.875rem;
}
.c-card-highlight img {
  border-radius: 0 12px 12px 0;
  max-width: 258px;
}
.c-card-highlight__side {
  margin-bottom: 0;
}
.app-card-highlight--normal-order-stack .c-card-highlight__side {
  margin: -24px 0 0 -24px;
}
@media only screen and (min-width: 1024px) {
  .c-card-highlight {
    padding: 24px;
  }
  .app-card-highlight--normal-order-stack,
  .c-card-highlight--stack {
    flex-direction: column;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-highlight--normal-order-stack .c-card-highlight__side {
    align-self: start;
    margin: 0;
  }
  .c-card-highlight--stack {
    flex-direction: column-reverse;
  }
  .c-card-highlight__content {
    display: block;
    flex-direction: unset;
    justify-content: unset;
  }
  .c-card-highlight--stack .c-card-highlight__side {
    max-width: none;
    margin: -24px -24px 0;
  }
  .c-card-highlight--stack .c-card-highlight__heading {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
  .c-card-highlight__heading--secondary {
    font-size: 1.25rem;
  }
  .c-card-highlight--stack img {
    max-width: initial;
    border-radius: initial;
  }
}
.app-homepage-card-layout {
  display: flex;
  flex-direction: column;
}
.app-homepage-card-layout > :not(:last-child) {
  margin-bottom: 8px;
}
.app-homepage-card-layout + .app-homepage-card-layout {
  padding-top: 8px;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-card-layout {
    flex-direction: row;
  }
  .app-homepage-card-layout > * {
    flex: 1 1 261px;
  }
  .app-homepage-card-layout > :not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
  .app-homepage-card-layout + .app-homepage-card-layout {
    padding-top: 24px;
  }
}
.app-homepage-featured-books__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(142px, 50%));
  justify-content: space-evenly;
}
.app-homepage-featured-books__list > li {
  border: 1px solid #cedbe0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-bottom: 16px;
}
.app-homepage-featured-books__list img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.app-homepage-featured-books__cover {
  min-height: 265px;
  background-color: #f0f7fc;
}
.app-homepage-featured-books__content {
  line-height: 1.2;
  padding: 8px;
}
.app-homepage-featured-books__link {
  font-size: 0.875rem;
  font-weight: 700;
  color: inherit;
}
.app-homepage-featured-books__link:hover,
.app-homepage-featured-books__link:visited {
  color: inherit;
}
@media only screen and (min-width: 480px) {
  .app-homepage-featured-books__list {
    grid-template-columns: repeat(auto-fit, 142px);
  }
  .app-homepage-featured-books__cover {
    min-height: 220px;
  }
}
.app-homepage-masthead {
  min-height: 332px;
  position: relative;
  padding-top: 16px;
  margin-bottom: 32px;
}
.app-homepage-masthead:before {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 332px;
  content: "";
  display: block;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.app-homepage-masthead--background-1 {
  min-height: 310px;
  padding-top: 24px;
  padding-bottom: 48px;
  margin-bottom: 0;
}
.app-homepage-masthead--background-1::before {
  background-color: #011d44;
  background-image: url(./images/homepage-background-1-0041b2aec7.jpg);
  height: 100%;
}
@media only screen and (min-width: 480px) {
  .app-homepage-masthead {
    padding-top: 48px;
    margin-bottom: 32px;
  }
  .app-homepage-masthead--background-1 {
    padding-top: 48px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-masthead--background-1 {
    min-height: 338px;
  }
}
.app-homepage-masthead--background-2::before {
  background-color: #ab2500;
  background-image: url(./images/homepage-background-2-29645aa1f9.jpg);
}
.app-homepage-masthead--background-3::before {
  background-color: #014425;
  background-image: url(./images/homepage-background-3-1596972f4d.jpg);
}
.app-homepage-masthead--background-4::before {
  background-color: #1e1439;
  background-image: url(./images/homepage-background-4-ddbbf6a424.jpg);
}
.app-homepage-masthead--background-5 {
  min-height: auto;
  padding-bottom: 48px;
}
.app-homepage-masthead--background-5::before {
  background-color: #f0f7fd;
  height: 100%;
}
@media only screen and (min-width: 480px) {
  .app-homepage-masthead--background-5 {
    padding-top: 32px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-masthead--background-5 {
    padding-top: 48px;
  }
}
.app-homepage-masthead--no-background {
  padding-top: 0;
}
.app-homepage-masthead--no-background::before {
  display: none;
}
.app-homepage-masthead__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
.app-homepage-masthead__heading {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-masthead__line-break {
    display: block;
  }
}
.app-homepage-masthead__heading--light {
  color: #fff;
  text-shadow: 0 0 4px #000;
}
.app-homepage-masthead__form {
  line-height: 1.4;
}
.app-homepage-masthead__label {
  color: #fff;
  text-shadow: 0 0 4px #000;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 8px;
}
@media only screen and (min-width: 480px) {
  .app-homepage-masthead__label {
    font-size: 1.25rem;
  }
}
.app-homepage-masthead__search-container {
  position: relative;
  margin-bottom: 24px;
}
input.app-homepage-masthead__input {
  border: 1px solid #000;
  width: 100%;
  padding: 8px 48px 8px 16px;
  background-color: #fff;
  border-radius: 24px;
  min-height: 48px;
}
input.app-homepage-masthead__input:focus {
  outline-color: #f0f7fc;
}
.app-homepage-masthead__button {
  background-color: transparent;
  border: 0;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 8px;
}
.app-homepage-masthead__icon {
  transform: translate(0, 0);
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 24px;
  vertical-align: middle;
  fill: #000;
}
.app-homepage-masthead__stats {
  color: #fff;
  text-shadow: 0 0 4px #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.app-homepage-masthead__stats-item {
  display: flex;
  align-items: baseline;
  line-height: 1.4;
  margin: 0;
  padding-bottom: 8px;
}
.app-homepage-masthead__stats-number {
  font-size: 1rem;
  font-weight: 700;
  padding-right: 3px;
}
.app-homepage-masthead__stats-label {
  font-size: 1rem;
}
@media only screen and (min-width: 480px) {
  .app-homepage-masthead__stats {
    flex-direction: row;
  }
  .app-homepage-masthead__stats-item {
    flex-direction: column;
    padding-bottom: 16px;
  }
  .app-homepage-masthead__stats-item:not(:last-child) {
    padding-right: 64px;
  }
  .app-homepage-masthead__stats-number {
    font-size: 1.75rem;
    padding-right: 0;
  }
  .app-homepage-masthead__stats-label {
    font-size: 1rem;
  }
}
.app-homepage-masthead__heading-layout {
  display: flex;
  align-items: center;
}
.app-homepage-masthead__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #f0f7fc;
  border-radius: 50%;
  color: #222;
  font-size: 24px;
}
.app-homepage-masthead__icon-container img {
  width: 60%;
}
.app-homepage-masthead__icon-container + * {
  padding-left: 8px;
}
@media only screen and (min-width: 480px) {
  .app-homepage-masthead__icon-container {
    font-size: 32px;
    width: 64px;
    height: 64px;
  }
  .app-homepage-masthead__icon-container img {
    width: auto;
  }
  .app-homepage-masthead__icon-container + * {
    padding-left: 16px;
  }
}
.app-homepage-masthead__icon-container--light {
  background-color: #fff;
}
.app-homepage-section-layout {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.app-homepage-section-layout > * {
  margin: 0;
}
.app-homepage-section-layout__sidebar > * {
  margin-bottom: 24px;
}
.app-homepage-section-layout > :not(.app-homepage-section-layout__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
}
.app-homepage-section-layout__main-content > :last-child,
.app-homepage-section-layout__sidebar > :last-child {
  margin-bottom: 0;
}
.app-homepage-section-layout__main-content:not(:last-child) {
  flex-basis: 100%;
  width: 100%;
}
.app-homepage-section-layout__sidebar {
  margin-bottom: 24px;
  flex-basis: 100%;
  width: 100%;
}
.app-homepage-section-layout__sidebar:empty {
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-section-layout {
    justify-content: space-between;
  }
  .app-homepage-section-layout__sidebar {
    flex-grow: 1;
    flex-basis: 300px;
    margin-top: 8px;
  }
  .app-homepage-section-layout .app-homepage-section-layout__sidebar {
    flex-grow: initial;
  }
  .app-homepage-section-layout > :first-child {
    margin-right: 40px;
  }
}
.app-homepage-tagline {
  margin-top: -74px;
  margin-bottom: 32px;
}
.app-homepage-tagline > div {
  padding-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-tagline {
    margin-top: -148px;
    margin-bottom: 64px;
  }
  .app-homepage-tagline > div {
    padding-top: 64px;
  }
  .app-homepage-tagline__main-content {
    padding-top: 24px;
  }
}
.app-homepage-tagline__rhombus {
  border-color: #f58220;
  -o-border-image: url./images/homepage-tagline-e18e535f9f.svg) 80;
  border-image: url./images/homepage-tagline-e18e535f9f.svg) 80;
  border-style: inset;
  border-width: 60px;
  height: auto;
  min-height: 233px;
  max-width: 233px;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-tagline__rhombus {
    border-width: 80px;
    min-height: 300px;
    min-width: 300px;
    width: auto;
  }
}
.app-homepage-tagline__heading {
  margin-left: -30px;
  margin-top: 18px;
  width: 190px;
}
.app-homepage-tagline__heading span {
  display: block;
}
@media only screen and (min-width: 1024px) {
  .app-homepage-tagline__heading {
    font-size: 32px;
    margin-left: -42px;
  }
}
.app-homepage-tagline__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  font-size: 32px;
  margin-top: 16px;
  color: #08c;
}
.app-homepage-trending {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
}
@media only screen and (min-width: 768px) {
  .app-homepage-trending {
    flex-direction: row;
  }
}
.app-homepage-trending > * {
  flex: 1 1 auto;
  margin-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .app-homepage-trending > * {
    flex: 1 1 315px;
  }
}
@media only screen and (min-width: 876px) {
  .app-homepage-trending > * {
    flex: 1 1 320px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-trending > * {
    flex: 1 1 267px;
  }
}
@media only screen and (min-width: 1220px) {
  .app-homepage-trending > * {
    flex: 1 1 261px;
  }
}
.app-stack > * {
  margin: 0;
}
.app-stack > * + * {
  margin-top: 24px;
}
.l-grid {
  --grid--gap: 1em;
  --grid--basis: min(278px, 100%);
}
.u-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  width: auto;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.3;
  justify-content: center;
  padding: 16px 32px;
  transition: all 0.2s;
  border-radius: 32px;
  background-color: #01324b;
  background-image: none;
  border: 4px solid transparent;
  font-weight: 700;
}
.u-button svg,
.u-button--contrast svg,
.u-button--primary svg,
.u-button--secondary svg,
.u-button--tertiary svg {
  fill: currentColor;
}
.u-button,
.u-button:visited {
  color: #fff;
}
.u-button,
.u-button:hover {
  box-shadow: 0 0 0 1px #01324b;
  text-decoration: none;
}
.u-button:hover {
  border: 4px solid #fff;
}
.u-button:focus {
  border: 4px solid #fc0;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
}
.u-button:focus,
.u-button:hover {
  color: #01324b;
  background-color: #fff;
  background-image: none;
}
.u-button--primary:focus svg path,
.u-button--primary:hover svg path,
.u-button:focus svg path,
.u-button:hover svg path {
  fill: #01324b;
}
.u-button--primary {
  color: #fff;
  background-color: #01324b;
  background-image: none;
  border: 4px solid transparent;
  box-shadow: 0 0 0 1px #01324b;
  font-weight: 700;
}
.u-button--primary:visited {
  color: #fff;
}
.u-button--primary:hover {
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px #01324b;
  text-decoration: none;
}
.u-button--primary:focus {
  border: 4px solid #fc0;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
}
.u-button--primary:focus,
.u-button--primary:hover {
  color: #01324b;
  background-color: #fff;
  background-image: none;
}
.u-button--secondary {
  color: #01324b;
  background-color: #fff;
  border: 4px solid #fff;
  font-weight: 700;
}
.u-button--secondary:visited {
  color: #01324b;
}
.u-button--secondary:hover {
  border: 4px solid #01324b;
  box-shadow: none;
}
.u-button--secondary:focus,
.u-button--secondary:hover {
  color: #fff;
  background-color: #01324b;
}
.u-button--secondary:focus svg path,
.u-button--secondary:hover svg path,
.u-button--tertiary:focus svg path,
.u-button--tertiary:hover svg path {
  fill: #fff;
}
.u-button--tertiary {
  color: #666;
  background-color: #ebf1f5;
  border: 4px solid transparent;
  box-shadow: none;
  font-weight: 700;
}
.u-button--tertiary:visited {
  color: #666;
}
.u-button--tertiary:hover {
  border: 4px solid #01324b;
  box-shadow: none;
}
.u-button--tertiary:focus,
.u-button--tertiary:hover {
  color: #fff;
  background-color: #01324b;
}
.u-button--contrast {
  color: #fff;
  background-color: transparent;
  background-image: none;
  font-weight: 400;
}
.u-button--contrast:visited {
  color: #fff;
}
.u-button--contrast,
.u-button--contrast:hover {
  border: 4px solid #fff;
}
.u-button--contrast:focus {
  border: 4px solid #fff;
}
.u-button--contrast:focus,
.u-button--contrast:hover {
  color: #000;
  background-color: #fff;
  background-image: none;
}
.u-button--contrast:focus svg path,
.u-button--contrast:hover svg path {
  fill: #000;
}
.u-button--disabled,
.u-button:disabled {
  color: #000;
  background-color: transparent;
  background-image: none;
  border: 4px solid #ccc;
  font-weight: 400;
  opacity: 0.7;
  cursor: default;
}
.u-button--disabled svg,
.u-button:disabled svg {
  fill: currentColor;
}
.u-button--disabled:visited,
.u-button:disabled:visited {
  color: #000;
}
.u-button--disabled:hover,
.u-button:disabled:hover {
  border: 4px solid #ccc;
  text-decoration: none;
}
.u-button--disabled:focus,
.u-button:disabled:focus {
  border: 4px solid #ccc;
  text-decoration: none;
}
.u-button--disabled:focus,
.u-button--disabled:hover,
.u-button:disabled:focus,
.u-button:disabled:hover {
  color: #000;
  background-color: transparent;
  background-image: none;
}
.u-button--disabled:focus svg path,
.u-button--disabled:hover svg path,
.u-button:disabled:focus svg path,
.u-button:disabled:hover svg path {
  fill: #000;
}
.u-button--small,
.u-button--xsmall {
  font-size: 0.875rem;
  padding: 2px 8px;
}
.u-button--small {
  padding: 8px 16px;
}
.u-button--large {
  font-size: 1.125rem;
  padding: 10px 35px;
}
.u-button--full-width {
  display: flex;
  width: 100%;
}
.u-button--icon-left svg {
  margin-right: 8px;
}
.u-button--icon-right svg {
  margin-left: 8px;
}
.u-clearfix::after,
.u-clearfix::before {
  content: "";
  display: table;
}
.u-clear-both,
.u-clearfix::after {
  clear: both;
}
.u-clear-left {
  clear: left;
}
.u-clear-right {
  clear: right;
}
.u-clear-float {
  float: none;
}
.u-color-open-access {
  color: #b74616;
}
.u-color-inherit {
  color: inherit;
}
.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
.u-display-block {
  display: block;
}
.u-display-inline,
.u-list-comma-separated > li,
.u-list-inline > li {
  display: inline;
}
.u-display-inline-block {
  display: inline-block;
}
.u-display-flex {
  display: flex;
  width: 100%;
}
.u-display-inline-flex {
  display: inline-flex;
}
.u-display-grid {
  display: grid;
}
.u-display-table {
  display: table;
}
.u-flex-direction-row {
  flex-direction: row;
}
.u-flex-direction-column {
  flex-direction: column;
}
.u-flex-wrap {
  flex-wrap: wrap;
}
.u-align-items-center {
  align-items: center;
}
.u-align-items-baseline {
  align-items: baseline;
}
.u-justify-content-space-between {
  justify-content: space-between;
}
.u-justify-content-center {
  justify-content: center;
}
.u-justify-content-left {
  justify-content: left;
}
.u-flex-grow {
  flex: 1 0 auto;
}
.u-flex-grow-shrink {
  flex: 1 1 auto;
}
.u-flex-shrink {
  flex: 0 1 auto;
}
.u-flex-static {
  flex: 0 0 auto;
}
.u-align-self-center {
  align-self: center;
}
.u-align-self-start {
  align-self: flex-start;
}
.u-align-self-end {
  align-self: flex-end;
}
.u-float-right {
  float: right;
}
.u-float-left {
  float: left;
}
.u-display-none {
  display: none;
}
.js .u-js-hide,
.u-hide {
  display: none;
  visibility: hidden;
}
.js .u-js-hide:first-child + *,
.u-hide:first-child + * {
  -webkit-margin-before: 0;
  margin-block-start: 0;
}
.js .u-js-show,
.u-show {
  display: block;
  visibility: visible;
}
.js .u-js-show-inline,
.u-show-inline {
  display: inline;
  visibility: visible;
}
.js .u-js-visually-hidden,
.u-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.js .u-js-visually-hidden--off,
.u-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}
.u-invisible {
  visibility: hidden;
}
.js .u-js-hide-text,
.u-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}
.u-hide-overflow {
  overflow: hidden;
}
@media print {
  .u-hide-print {
    display: none;
  }
}
@media only screen and (min-width: 480px) {
  .js .u-js-hide-at-xs,
  .u-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .js .u-js-hide-at-xs:first-child + *,
  .u-hide-at-xs:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .js .u-js-show-at-xs,
  .u-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .js .u-js-hide-at-sm,
  .u-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .js .u-js-hide-at-sm:first-child + *,
  .u-hide-at-sm:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .js .u-js-show-at-sm,
  .u-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 876px) {
  .js .u-js-hide-at-md,
  .u-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .js .u-js-hide-at-md:first-child + *,
  .u-hide-at-md:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .js .u-js-show-at-md,
  .u-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .js .u-js-hide-at-lg,
  .u-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .js .u-js-hide-at-lg:first-child + *,
  .u-hide-at-lg:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .js .u-js-show-at-lg,
  .u-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .js .u-js-hide-at-xl,
  .u-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .js .u-js-hide-at-xl:first-child + *,
  .u-hide-at-xl:first-child + * {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .js .u-js-show-at-xl,
  .u-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}
.u-full-height {
  height: 100%;
}
.u-vertical-align-baseline {
  vertical-align: baseline;
}
.u-vertical-align-top {
  vertical-align: top;
}
.u-vertical-align-bottom {
  vertical-align: bottom;
}
.u-vertical-align-middle {
  vertical-align: middle;
}
.u-link-text,
.u-link-text--large {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
}
.u-link-text--large {
  font-size: 1.25rem;
}
.u-list-style-number {
  list-style-type: decimal;
}
.u-list-style-bullet {
  list-style-type: disc;
}
.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}
.u-list-style-none {
  list-style-type: none;
}
.u-list-inline,
.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}
.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}
.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}
.u-overflow-hidden {
  overflow: hidden;
}
.u-overflow-visible {
  overflow: visible;
}
.u-position-absolute {
  position: absolute;
}
.u-position-relative {
  position: relative;
}
.u-position-top {
  top: 0;
}
.u-position-left {
  left: 0;
}
.u-position-bottom {
  bottom: 0;
}
.u-position-right {
  right: 0;
}
.u-position-negative-right {
  right: 100%;
}
.u-scrollbar-thin {
  scrollbar-width: thin;
}
.u-ma-0 {
  margin: 0;
}
.u-ma-2 {
  margin: 2px;
}
.u-ma-4 {
  margin: 4px;
}
.u-ma-8 {
  margin: 8px;
}
.u-ma-16 {
  margin: 16px;
}
.u-ma-24 {
  margin: 24px;
}
.u-ma-32 {
  margin: 32px;
}
.u-ma-48 {
  margin: 48px;
}
.u-ma-64 {
  margin: 64px;
}
.u-ma-auto {
  margin: auto;
}
.u-mt-0 {
  margin-top: 0;
}
.u-mt-2 {
  margin-top: 2px;
}
.u-mt-4 {
  margin-top: 4px;
}
.u-mt-8 {
  margin-top: 8px;
}
.u-mt-16 {
  margin-top: 16px;
}
.u-mt-24 {
  margin-top: 24px;
}
.u-mt-32 {
  margin-top: 32px;
}
.u-mt-48 {
  margin-top: 48px;
}
.u-mt-64 {
  margin-top: 64px;
}
.u-mt-auto {
  margin-top: auto;
}
.u-mr-0 {
  margin-right: 0;
}
.u-mr-2 {
  margin-right: 2px;
}
.u-mr-4 {
  margin-right: 4px;
}
.u-mr-8 {
  margin-right: 8px;
}
.u-mr-16 {
  margin-right: 16px;
}
.u-mr-24 {
  margin-right: 24px;
}
.u-mr-32 {
  margin-right: 32px;
}
.u-mr-48 {
  margin-right: 48px;
}
.u-mr-64 {
  margin-right: 64px;
}
.u-mr-auto {
  margin-right: auto;
}
.u-mb-0 {
  margin-bottom: 0;
}
.u-mb-2 {
  margin-bottom: 2px;
}
.u-mb-4 {
  margin-bottom: 4px;
}
.u-mb-8 {
  margin-bottom: 8px;
}
.u-mb-16 {
  margin-bottom: 16px;
}
.u-mb-24 {
  margin-bottom: 24px;
}
.u-mb-32 {
  margin-bottom: 32px;
}
.u-mb-48 {
  margin-bottom: 48px;
}
.u-mb-64 {
  margin-bottom: 64px;
}
.u-mb-auto {
  margin-bottom: auto;
}
.u-ml-0 {
  margin-left: 0;
}
.u-ml-2 {
  margin-left: 2px;
}
.u-ml-4 {
  margin-left: 4px;
}
.u-ml-8 {
  margin-left: 8px;
}
.u-ml-16 {
  margin-left: 16px;
}
.u-ml-24 {
  margin-left: 24px;
}
.u-ml-32 {
  margin-left: 32px;
}
.u-ml-48 {
  margin-left: 48px;
}
.u-ml-64 {
  margin-left: 64px;
}
.u-ml-auto {
  margin-left: auto;
}
.u-pa-0 {
  padding: 0;
}
.u-pa-2 {
  padding: 2px;
}
.u-pa-4 {
  padding: 4px;
}
.u-pa-8 {
  padding: 8px;
}
.u-pa-16 {
  padding: 16px;
}
.u-pa-24 {
  padding: 24px;
}
.u-pa-32 {
  padding: 32px;
}
.u-pa-48 {
  padding: 48px;
}
.u-pa-64 {
  padding: 64px;
}
.u-pt-0 {
  padding-top: 0;
}
.u-pt-2 {
  padding-top: 2px;
}
.u-pt-4 {
  padding-top: 4px;
}
.u-pt-8 {
  padding-top: 8px;
}
.u-pt-16 {
  padding-top: 16px;
}
.u-pt-24 {
  padding-top: 24px;
}
.u-pt-32 {
  padding-top: 32px;
}
.u-pt-48 {
  padding-top: 48px;
}
.u-pt-64 {
  padding-top: 64px;
}
.u-pr-0 {
  padding-right: 0;
}
.u-pr-2 {
  padding-right: 2px;
}
.u-pr-4 {
  padding-right: 4px;
}
.u-pr-8 {
  padding-right: 8px;
}
.u-pr-16 {
  padding-right: 16px;
}
.u-pr-24 {
  padding-right: 24px;
}
.u-pr-32 {
  padding-right: 32px;
}
.u-pr-48 {
  padding-right: 48px;
}
.u-pr-64 {
  padding-right: 64px;
}
.u-pb-0 {
  padding-bottom: 0;
}
.u-pb-2 {
  padding-bottom: 2px;
}
.u-pb-4 {
  padding-bottom: 4px;
}
.u-pb-8 {
  padding-bottom: 8px;
}
.u-pb-16 {
  padding-bottom: 16px;
}
.u-pb-24 {
  padding-bottom: 24px;
}
.u-pb-32 {
  padding-bottom: 32px;
}
.u-pb-48 {
  padding-bottom: 48px;
}
.u-pb-64 {
  padding-bottom: 64px;
}
.u-pl-0 {
  padding-left: 0;
}
.u-pl-2 {
  padding-left: 2px;
}
.u-pl-4 {
  padding-left: 4px;
}
.u-pl-8 {
  padding-left: 8px;
}
.u-pl-16 {
  padding-left: 16px;
}
.u-pl-24 {
  padding-left: 24px;
}
.u-pl-32 {
  padding-left: 32px;
}
.u-pl-48 {
  padding-left: 48px;
}
.u-pl-64 {
  padding-left: 64px;
}
.u-keyline,
.u-keyline--thick {
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.u-keyline--thick {
  border-bottom: 2px solid #999;
}
.u-shadow {
  border: 1px solid #000;
  box-shadow: 5px 5px 5px #000;
}
.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.u-monospace {
  font-family: monospace;
}
.u-sans-serif {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.u-serif {
  font-family: "Merriweather", serif;
}
.u-small-caps {
  font-size: 0.8em;
  text-transform: uppercase;
}
.u-text-right {
  text-align: right;
}
.u-text-left {
  text-align: left;
}
.u-text-center {
  text-align: center;
}
.u-text-normal {
  font-weight: 400;
}
.u-text-bold {
  font-weight: 700;
}
.u-text-small {
  font-size: 0.875rem;
}
.u-meta-large,
.u-meta-medium,
.u-meta-small {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.u-meta-large {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}
.u-meta-medium,
.u-meta-small {
  font-size: 1.125rem;
}
.u-meta-small {
  font-size: 0.875rem;
}
.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}
.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
.u-upper {
  text-transform: uppercase;
}
.u-lower {
  text-transform: lowercase;
}
.u-capitalize {
  text-transform: capitalize;
}
.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}
.u-unstyled {
  font-weight: 400;
  font-style: normal;
}
.u-h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}
.u-h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
}
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  line-height: 1.2;
  font-weight: 700;
}
.u-h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}
.u-h4,
.u-h5 {
  font-size: 1.25rem;
}
.u-h5 {
  font-size: 1.125rem;
}
.u-mbs-0 {
  -webkit-margin-before: 0 !important;
  margin-block-start: 0 !important;
}
.u-link-inherit,
.u-link-inherit.hover,
.u-link-inherit.visited,
.u-link-inherit:hover,
.u-link-inherit:visited {
  color: inherit;
}
.u-link-faux-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.c-account-nav__anchor.is-open.eds-c-header__link::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAyNWU4ZCIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=);
}