*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.cart-main-container{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.cart-item-body{
padding: 20px;
}
.cart-item-section{
    
}
.cart-container{
height: 200px;
width: 100%;
}
.cart-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
/* box-shadow: 1px 2px 2px rgba(4, 0, 18, 0.505); */

}
.cart-navbar{
    border-bottom-width: 1px;
    border-bottom-color: black;
}
.separator{
    color: rgb(219, 224, 228);
    font-size: 30px;
}
.site-title{
font-weight: bolder;
font-size: 30px;
color: rgb(21, 17, 95);
}
.auth-link{
    color: rgb(45, 43, 43);
    cursor: pointer;
    cursor: pointer;
}
.horizontal-line{
    margin: 15px 0px 15px;
}
.arrow-wrapper{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-self: flex-start;
    padding: 20px;
}
.back-arrow-icon{
color: gray;
font-size: 12px;
}
.back-arrow-text{
    
}
.cart-item{
    
}
.cart-item-title{
    font-size: 16px;
}
.cart-item-paragraph{
    font-size: 16px;
    font-weight: lighter
}
.cart-total{
    font-size: 16px;
}
.cart-checkout{
    background-color: rgb(41, 91, 140);
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 100;
}
.cart-item-section{
margin-top: 30px;
display: flex;
justify-content: space-between;
width: 60%;
}
.cart-total{
    color: rgb(49, 47, 47);
}
.tax-instruction{
    color: gray;
    font-size: 14px;
    font-weight: lighter;
    text-align: left;
}
.cart-item-cost-total{
    color: rgb(36, 34, 34);
    font-size: 14px;
    text-align: left;
}
.cart-item-info-wrap{
display: flex;
justify-content: space-between;
/* align-items: center; */
flex-direction: column;
}
.cart-item-cost{
text-align: right;
color: rgb(36, 34, 34);
font-weight: lighter;
font-size: 14px;
}
.coupon-section{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    width: 60%;
    margin: 20px 0px;
}
.coupon-code{
    font-weight: lighter;
    color: gray;
}
.coupon-right-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.coupon-input{
    padding: 10px;
    color: rgb(36, 34, 34);
    font-size: 14px;
    font-weight: lighter;
    flex-basis: 80%;
    width: 100%;
}
.apply-coupon{
    background-color: rgb(242, 245, 247);
    color: rgba(7, 104, 207, 0.724);
    border: thin solid gray;
    border-radius: 2px;
    font-size: 14px;
    flex-basis: 5%;
    width: 100%;
    font-weight: lighter;
    padding: 10px 35px;
}
.cart-items-container{
    box-shadow: 0px 1px 0px 0px rgb(220, 220, 220);

}
.cart-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;   
    box-shadow: 0px 1px 0px 0px rgb(220, 220, 220);
    padding: 20px;
}
.cart-item-image{
    flex-basis: 40%;
    width: 300px;
}
.cart-item-middle-body{
    flex-basis: 50%;
    width: 100%;
}
.cart-item-middle-paragraph{
    color: gray;
    font-size: 12px;
}
.cart-item-middle-link{
    color: gray;
    font-size: 12px;
}
.cart-item-image{
    flex-basis: 5%;
    width: 100%;
}
.cart-item-middle-down-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    align-self: flex-start;
}
.cart-item-middle-book-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
}
.qty{
    font-size: 14px;
}
.qty-input{
    width: 30px;
    padding-left: 4px;
    font-size: 14px;
    color: gray;
}
.book-type{
    font-size: 14px;
}
.remove-text,.remove-icon{
    color: rgb(53, 101, 150);
    font-size: 12px;
    cursor: pointer;
}
.cart-item-first-footer{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    width: 100%;   
    padding: 30px;
    box-shadow: 0px 1px 0px 0px rgb(220, 220, 220);
}
.cart-item-first-footer-left{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;   
    flex-direction: column;
    flex-basis: 25%;
    width: 100%;   
}
.cart-item-first-footer-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    line-height: 16px;
    flex-basis: 50%;
    width: 100%;   
    flex-direction: column;
}
.cart-item-first-footer-title{
    text-align: center;
    font-size: 16px;
}
.cart-item-first-footer-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: baseline;      
    width: 100%;
}
.cart-item-first-footer-link{
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    color: rgb(65, 62, 62);
}
.cart-item-first-footer-left-links{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    line-height: normal;
}
.footer-ecom-iamge{
    width: 60%;
}
.cart-item-first-footer-left-paragraph{
 padding: 20px;   
 font-size: 12px;
 font-weight: lighter;
 color: gray;
}
.cart-item-first-footer-right-paragraph{
    padding: 20px;   
    font-size: 12px;
    font-weight: lighter;
    color: gray;
}

.cart-item-last-footer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 30px;
    background-color: rgba(230, 237, 237, 0.708);
    padding: 10px 20px;
}
.cart-item-last-footer-image{
    flex-basis: 8%;
    width: 200px;
}
.cart-item-last-footer-right-container{
    flex-basis: 90%;
    width: 100%;   
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}
.cart-item-last-footer-right-inner-text{
    font-size: 11px;
    font-weight: lighter;
    color: #000;
}