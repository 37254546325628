*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.cart-main{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.advertisement{
    font-weight: lighter;
    color: rgb(88, 84, 84);
}
.car-advert-wrapper{
width: 100%;
background-color: rgb(196, 198, 200);
    display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.cart-advert{
    height: 110px;
    width: 100%;
    display: flex;
justify-content: center;
align-items: center;
}
.cart-advert-img{
    align-self: center;
}
.cart-nav{
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
}
.cart-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
box-shadow: 1px 2px 2px rgb(220, 220, 220);
}
.cart-menu{
    width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 15px;
box-shadow: 2px 2px 2px rgb(20, 21, 23);
}
.cart-link{
    list-style: none;
    cursor: pointer;
    color: #000;
    font-weight: bolder;
    font-size: 16px;
}
.cart-menu-items{
    display: flex;
justify-content: flex-start;
align-items: center;
flex-basis: 90%;
gap: 30px;
width: 100%;
}
.cart-tools{
    display: flex;
justify-content: center;
align-self: flex-end;
align-items: center;
flex-basis: 10%;
width: 100%;
gap: 12px;

}
.separator{
    color: rgb(219, 224, 228);
    font-size: 30px;
}
.site-title{
font-weight: bolder;
font-size: 30px;
color: rgb(21, 17, 95);
}
.auth-link{
    color: rgb(45, 43, 43);
    cursor: pointer;
}
.horizontal-line{
    margin: 15px 0px 15px;
}
.search-icon,
.cart-icon{
    font-weight: lighter;
    cursor: pointer;
}
/* .left-current-cart-item{
flex-basis: 65%;
width: 100%;
    height: auto;
    height: fit-content;
    position: relative;
} */
.left-container-top{
background-color: rgb(93, 66, 112);
width: 100%;
height: 50px;
}
.left-container-bottom{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    padding: 20px;
    position: absolute;
    top: 0;
}
.current-cart-item-text-wrapper{
    display: flex;
    justify-content: center;
    line-height: 10px;
    flex-direction: column;
    height: 200px;
}
.current-cart-item-image{
    border: 2px solid white;
    border-radius: 3px;
    
}
.expand-img{
    opacity: 0.5;
    background-color: white;
    position: absolute;
    left: 102px;
    cursor: pointer;
}
.current-cart-item-title{
    font-weight: bolder;
    font-size: 20px;
}
.current-cart-item-year{
    color: gray;
    font-weight: lighter;
    font-size: 12px;
}
.right-current-cart-item{
flex-basis: 35%;
width: 100%;
height: 50px;
background-color: rgb(124, 108, 137);
}
.extra-links{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    align-self: flex-start;
    gap: 20px;
    padding: 10px 20px;
}
.home-link,.book-caption,.right-arrow{
color: rgb(250, 247, 247);
}
.home-link{
cursor: pointer;
}
.right-arrow{
    font-size: 20px;
}
.book-caption{

}
.preview-main{
    width: 100%;
    background: #000;
    height: 100vh;
    margin: auto;
}
.image-preview{
    width: 300px;
    height: 500px;

}

.left-section{
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 30px;
}
.left-inner-section{
    width: 100%;
    box-shadow: 1px 1px 2px 2px rgb(220, 220, 220);
    padding: 20px;
}
.editors{
}
.authors-link{
margin: 20px 5px;
}

.editors-paragraph {
    font-weight: lighter;
    font-size: 14px;
}
.show-more-icon,.show-more{
    color: rgb(0, 128, 255);
    font-size: 12px;
}
.show-more{
font-size: 14px;
color: rgb(0, 128, 255);
text-decoration: underline rgb(0, 128, 255);
cursor: pointer;
}
.description-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.description-figure{

}
.description-text{
    font-weight: lighter;
    font-size: 14px;
    color: rgb(181, 174, 174);
}
.contents{
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 10px;
    flex-direction: column;
}
.content-item{
    color: rgb(5, 77, 148);
    text-align: left;
    font-weight: lighter;
    font-size: 14px;
}
.right-section{
    flex-basis: 30%;
    width: 100%;
    box-shadow: 1px 2px 2px rgb(220, 220, 220);
}
.institution-login{
    background-color: rgb(41, 91, 140);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    cursor: pointer;
    border-radius: 2px;
    height: 50px;
}
.institution-text{
    color: white;
    font-weight: lighter;
    font-size: 14px;
}
.institution-arrow{
    color: white;
}
.buy-it-now{
    color: rgb(32, 31, 31);
    padding: 10px;
    font-weight: lighter;
    font-size: 16px;
}
.right-widget-paragraph{
    padding: 10px;
    color: rgb(32, 31, 31);
    text-align: center;
    font-weight: lighter;
    font-size: 12px;
}
.links-container{
    padding: 10px;
}
.link-out-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    border: 1px solid gray;
    padding: 5px 10px;
    background-color: rgb(236, 245, 246);
}
.link-out{
    font-size: 12px;
    font-weight: lighter;
}
.icon-out{
    color:rgb(41, 91, 140)
}
.upper-links-wrapper{
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    border: 1px solid gray;
    padding: 5px 10px;
    background-color: rgb(236, 245, 246);
}
.upper-link-title{
    font-size: 12px;
    font-weight: lighter;
    color: rgb(41, 91, 140);
}
.buy-book-btn{
    width: 100%;
    background-color: rgb(41, 91, 140);
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    padding: 10px 30px;
}
.upper-links-wrapper-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.upper-links-item-wrapper{
    background-color: white;
}
.upper-link-cost{
    font-size: 14px;
    color: rgb(8, 78, 147);
}
.upper-links-wrapper-top-isolate{
    display: flex;
    justify-content: space-around;
    align-items: center;   
    gap: 5px;
}
.add-to-cart-modal{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 450px;
    margin: 40vh auto;
    height: 150px;
    background-color: white;
    border: thin solid gray;
    padding: 10px;
    box-shadow: 2px 1px 2px rgb(220, 220, 220);
}
.add-to-cart-inner-top{
    display: flex;
    justify-content: space-between;
    align-items: center;   
    width: 100%;
}
.added-to-your-cart{
    color: gray;
    font-size: 14px;
}
.add-to-cart-inner-right{
    display: flex;
    justify-content: space-around;
    align-items: center;   
    gap: 5px;
    align-self: flex-start;   
}
.close-cart-icon,.close-cart{
    color: gray;
    font-weight: lighter;
    font-size: 12px;
    color: rgba(31, 39, 185, 0.683);
    cursor: pointer;
}
.add-to-cart-btn-group{
    display: flex;
    justify-content: space-between;
    align-items: center;   
    gap: 5px;   
    width: 100%;
}
.go-to-checkout-btn{
    background-color: rgb(41, 91, 140);
    color: white;
    flex-basis: 48%;
    width: 100%;
    border-radius: 4px;
    font-weight: lighter;
    font-size: 12px;
}
.go-to-cart-btn{
    color: gray;
    flex-basis: 48%;
    width: 100%;
    border-radius: 4px;
    font-weight: lighter;
    font-size: 12px;
    background-color: white;
}

.go-to-checkout-btn,.go-to-cart-btn{
    border: thin solid rgb(214, 206, 206);
    padding: 10px 50px;
    text-decoration: none;
}