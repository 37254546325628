*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.login-options-main{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.login-options-header{
width: 100%;
border-bottom: 2px solid rgb(1, 1, 27);
}
.login-options-nav{
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
}
.login-options-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
}
.auth-link{
    color: rgb(45, 43, 43);
    cursor: pointer;
}
/* Main Body */
.login-options-main-container{
    width: 100%;
    background-color: rgb(223, 220, 220);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 70px 0px 50px 20px;
}
.login-options-container{
    width: 90%;
    display: flex;
    align-self: flex-start;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    margin: auto;
}
.login-options-main-container-info-title{
    font-size: 28px;
    text-align: center;
    padding: 20px;
}
.login-options-main-container-info-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-bottom: 4px solid var(--secondary-color);
    box-shadow: 1px 1px 1px 1px rgb(220,220,220);
}
.login-options-main-container-info{
    font-weight: 400;
    font-size: 14px;
}
.info-icon{
    color: var(--secondary-color);
}
.login-options-main-container-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.login-options-main-container-item{
    flex-basis: 45%;
    width: 100%;
    height: fit-content;
    background-color: #f7f2f2;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
}
/* End of Main */
/* Footer */
.editorial-footer{
    width: 100%;
    background-color:var(--primary-color);
    padding: 25px 10px;
}
.editorial-footer-top{
   display: flex;
   justify-content: space-between;
   align-items: baseline;
   width: 100%;
}
.editorial-footer-top-items{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    width: 100%;
    flex-wrap: wrap;
}
.editorial-footer-top-item{
   line-height: 1;
}
.editorial-footer-top-item-title{
    color: white;
    font-weight: 600;
    font-size: 24px;
}
.editorial-footer-top-item-description{
    color: white;
    font-weight: lighter;
    font-size: 16px;
    text-decoration: underline;
}
.editorial-footer-divider{
    width: 100%;
    height: 2px;
    background-color: white;
    margin-top: 35px;
}
.editorial-footer-top-item-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}
.editorial-footer-top-item-top-item{
    color: white;
    font-weight: lighter;
    font-size: 16px;
}
.editorial-footer-bottom-bottom{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 18px;
    margin-top: 30px;
}
.bottom-footer-text{
    color: white;
}
.login-options-form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
}
.login-options-input{
padding: 8px;
border-radius: 4px;
width: 100%;
align-self: flex-start;
border: thin solid gray;
outline: none;
}
.login-options-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
 text-decoration: none;
 padding: 8px;
    border-radius: 30px;
    background-color: var(--dark-blue-color);
    color: white;
    cursor: pointer;
}
.login-options-btn-orcid{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
 text-decoration: none;
 padding: 8px;
    border-radius: 30px;
    background-color: var(--dark-green-color);
    color: white;
}

.login-options-btn-icon{
    color: white;
}
.login-options-login-icon{
    width: 100%;
    cursor: pointer;
}
.refister-title{
    font-weight: 600;
    text-align: center;
    font-size: 26px;
    color: var(--primary-color);
}
.login-options-main-container-item-full{
    flex-basis: 60%;
    width: 100%;
    margin: auto;
    height: fit-content;
    background-color: #f7f2f2;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
}
.login-options-btn-title{
    font-size: 16px;
    font-weight: 400;
    border: none;
    background: none;
    color: white;
}
.login-options-input{
    font-size: 14px;
    font-weight: 200;
}
/* Mobile View */
@media only screen and (max-width:500px) {
    .login-options-main-container{
        flex-basis: 100%;
        padding: 0px;
        justify-content: center;
    }
    .login-options-main-container-info-container{
        justify-content: space-between;
        gap: 5px;
    }
    .login-options-container{
        width: 100%;
        display: flex;
        align-self: center;
        padding: 5px;
    }
    .login-options-main-container-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;
    }
    .login-options-main-container-info-title{
        font-size: 16px;
        font-weight: 100;
        color: var(--primary-color);
        text-align: center;
        padding: 20px;
    }
    .or{
        font-weight: 300;
        font-size: 16px;
    }
    .refister-title{
        font-weight: 600;
        font-size: 14px;
    }
    .login-options-main-container-item{
        flex-basis: 100%;
    width: 100%;
    height: fit-content;
    background-color: #f7f2f2 !important;
    border-radius: 5px;
    padding: 15px;
    }
    .login-options-form{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        flex-direction: column;
        flex-basis: 100%;
    }
    .login-options-email{
        font-weight: 300;
        font-size: 16px;
    }
    .login-options-input{
        font-size: 14px;
        font-weight: 200;
    }
    .login-options-btn-title{
        font-size: 12px;
        font-weight: 300;
    }
    
}
.editorial-footer-top-item-top-item{
    cursor: pointer;
}
.editorial-footer-top-item-top-item:hover{
    color: #f7f2f2;
    text-decoration: none;
}