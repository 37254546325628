/* @media print { */

  body {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 0 !important;
  }
  body,
  div,
  h1,
  h2,
  h3,
  ol,
  ul {
    overflow: visible !important;
  }
  .u-icon {
    width: 1em;
    height: 1em;
  }
/* } */
@page {
  margin: 1cm;
}