*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.checkout-main-container{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.checkout-item-body{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
}
.checkout-container{
height: 200px;
width: 100%;
}
.checkout-items-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-basis: 60%;
    width: 100%;  
    padding: 20px;
    gap: 80px;
    box-shadow: 1px 1px 1px 0px rgb(220, 220, 220);
}
.progress-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.connector,.active-connector{
    width: 30%;
    height: 1px;
    background-color: rgb(194, 194, 200);
}
.connector{
    background-color: rgb(194, 194, 200);
}
.active-connector{
    background-color: rgb(65, 81, 165);
}
.level-point{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.level-caption{
    font-size: 14px;
}
.level-filled,.level-outlined{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-self: right;
    gap: 25px;
    flex-direction: column;
    
}
.level-outlined{
background-color: rgb(255, 255, 255);
border: thin solid gray;
}

.level-filled{
    background-color: rgb(65, 81, 165);
    color: rgb(65, 81, 165);
}
.progress-container-bottom{

}
.checkout-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
box-shadow: 1px 2px 2px rgba(4, 0, 18, 0.505);
}
.auth-title{
    font-size: 16px; 
}
.auth-info{
    font-size: 14px;
    font-weight: lighter;
}
.auth-btn{
    background-color: rgba(7, 60, 130, 0.642);
    padding: 15px 30px;
    border-radius: 3px;
    color: white;
    font-size: 14px;
    border: none;
    padding: 10px 30px;
    width: 100%;
}
.guest-btn{
    background-color: rgb(248, 245, 245);
    padding: 15px 30px;
    border-radius: 3px;
    color: rgba(8, 20, 151, 0.642);
    border: thin solid gray;
    font-weight: 350;
    font-size: 14px;
    padding: 10px 30px;
    width: 100%;
}
.checkout-navbar{
    border-bottom-width: 1px;
    border-bottom-color: black;
}
.separator{
    color: rgb(219, 224, 228);
    font-size: 30px;
}
.site-title{
font-weight: bolder;
font-size: 30px;
color: rgb(21, 17, 95);
}
.auth-link{
    color: rgb(45, 43, 43);
    cursor: pointer;
}
.checkout-horizontal-line{
    margin: 15px 0px 15px;
    background-color: gray;
}
.arrow-wrapper{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-self: flex-start;
    padding: 20px;
}
.back-arrow-icon{
color: gray;
font-size: 12px;
}
.checkout-item-title{
    font-size: 16px;
}
.checkout-item-paragraph{
    font-size: 16px;
    font-weight: lighter
}
.checkout-total{
    font-size: 16px;
}
.checkout-items-container{
    box-shadow: 0px 1px 0px 0px rgb(220, 220, 220);

}
.checkout-auth-main-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: thin solid gray;
    width: 100%;
    padding: 25px 50px;
}
.checkout-auth-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    gap: 15px;
}
.checkout-items-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-basis: 35%;
    width: 100%;  
    padding: 20px;
    gap: 10px;
    background-color: rgb(244, 238, 238);
}
.first-section-right{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.first-inner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.first-inner-left{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: 90%;
    width: 100%;
}
.left-inner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.left-inner-image-wrapper{
    flex-basis: 40%;
    width: 100%;
}
.inner-image{
    width: 100%;
}
.inner-inner-right{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 55%;
    width: 100%;
}
.checkout-inner-right-input{
    width: 50px;
    padding: 5px;
    color: gray;
    font-size: 14px;
}
.checkout-qyt-remove-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.checkout-qyt-remove-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.checkout-qyt-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.checkout-qty{
    font-size: 14px;
    font-weight: lighter;
}
.checkout-remove-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout-remove,.checkout-icon{
    font-size: 14px;
    color: rgb(53, 101, 150);
}
.checkout-sub-title{
font-size: 14px;
}
.checkout-book-type{
font-size: 14px;
font-weight: lighter;
color: gray;
}
.first-inner-left{
    display: flex;
    justify-content: space-between;
    align-items: baseline; 
    flex-direction: column;
    flex-basis: 80%;
    width: 100%;
}
.checkout-total{
    font-size: 16px;
}
.first-inner-right-text{
    font-weight: lighter;
    font-size: 12px;
}
.checkout-total{
    font-size: 16px;
}
.back-to-browsing-wrapper{
    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
}
.back-to-browsing-link{
    font-weight: 200;
    font-size: 14px;
    text-align: left;
    align-self: flex-start;
    text-decoration: underline;
    color: rgb(14, 93, 220);
    cursor: pointer;
}
.back-to-browsing-icon{
    transform: rotate(180deg);
}
/* Right widget end */
.checkout-item-first-footer{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    width: 100%;   
    padding: 30px;
    box-shadow: 0px 1px 0px 0px rgb(220, 220, 220);
}
.checkout-item-first-footer-left{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;   
    flex-direction: column;
    flex-basis: 25%;
    width: 100%;   
}
.checkout-item-first-footer-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    line-height: 16px;
    flex-basis: 50%;
    width: 100%;   
    flex-direction: column;
}
.checkout-item-first-footer-title{
    text-align: center;
    font-size: 16px;
}
.checkout-item-first-footer-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: baseline;      
    width: 100%;
}
.checkout-item-first-footer-link{
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    color: rgb(65, 62, 62);
}
.checkout-item-first-footer-left-links{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    line-height: normal;
}
.footer-ecom-iamge{
    width: 60%;
}
.checkout-item-first-footer-left-paragraph{
 padding: 20px;   
 font-size: 12px;
 font-weight: lighter;
 color: gray;
}
.checkout-item-first-footer-right-paragraph{
    padding: 20px;   
    font-size: 12px;
    font-weight: lighter;
    color: gray;
}

.checkout-item-last-footer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 30px;
    background-color: rgba(230, 237, 237, 0.708);
    padding: 10px 20px;
}
.checkout-item-last-footer-image{
    flex-basis: 8%;
    width: 200px;
}
.checkout-item-last-footer-right-container{
    flex-basis: 90%;
    width: 100%;   
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}
.checkout-item-last-footer-right-inner-text{
    font-size: 11px;
    font-weight: lighter;
    color: #000;
}
