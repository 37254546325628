* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.cart-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cart-header {
  width: 100%;
}
.logo-img {
  width: 20%;
}
.cart-auth {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  /* box-shadow: 1px 2px 2px rgb(220, 220, 220); */
}
.cart-items-nav-bar {
  position: relative;
  width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-items-menu-container {
  flex-basis: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.cart-menu-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 90%;
  padding-left: 20px;
  width: 100%;
}
.cart-link {
  list-style: none;
  cursor: pointer;
  color: #201e1e;
  word-wrap: normal;
  font-weight: bold;
  font-size: 18px;
}
.search-wrapper {
  flex-basis: 60%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.search-img-wrapper{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
}
.search-img {
  width: 24px;
  height: 24px;
  text-decoration: underline;
  cursor: pointer;
}
.serch-text {
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
}
.serch-text:hover {
    text-decoration: underline;
  }
.cart-tools {
  flex-basis: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding-right: 20px;
  position: relative;
}
.search-wrapper-inner {
  flex-basis: 50%;
  width: 100%;
  padding: 1px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.editorial-search-container{
    display: flex;
    justify-content: baseline;
    align-items: flex-start;
    width: 70%;
    margin: auto;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
}
.editorial-search-title{
    text-align: left;
    font-weight: 600;
    font-size: 18px;
}
.editorial-search-input{
    width: 100%;
    padding: 5px;
}
.editorial-search-img{
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 6px;
    right: 5px;
    cursor: pointer;
}
.editorial-search-img-cancel{
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 6px;
    right: 40px !important;
    cursor: pointer;
}
.items-in-cart-wrapper {
  position: absolute;
  right: 60px;
  top: 0px;
  z-index: 0;
  background-color: #cf0606e1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.items-in-cart {
  color: white;
  z-index: 10;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
}
.separator{
  color: rgb(219, 224, 228);
  font-size: 30px;
}
.site-title {
  font-weight: bolder;
  font-size: 30px;
  color: rgb(21, 17, 95);
}
.auth-link {
  color: rgb(45, 43, 43);
  cursor: pointer;
}
.horizontal-line {
  margin: 15px 0px 15px;
}
.cart-icon {
  font-weight: lighter;
  cursor: pointer;
  font-size: 16px;
}
.cart-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary-color);
  padding: 30px;
}

.left-current-cart-item {
  flex-basis: 65%;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.left-current-cart-item-wrapper {
  width: 90%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.right-current-journal-item {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 170px;
}
.editorial-left-section {
  width: 90%;
  margin: auto;
}
.editorial-top-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
  margin-top: 20px;
}
.editorial-info-container {
  width: 50%;
  display: flex;
  justify-content: space-around;
  gap: 30px;
}
.editorial-info-wrapper {
  /* background-color: rgb(237, 244, 245); */
  width: 80%;
  display: flex;
  border-radius: 15px;
  padding: 5px;
  justify-content: center;
  gap: 6px;
  align-items: center;
}
.editorial-info-text {
  color: rgb(19, 75, 84);
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
}
.editorial-info-icon {
  color: rgb(19, 64, 84);
  width: 20px;
  height: 20px;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.submit-script-link-title {
  color: white !important;
  font-size: 30px;
  font-weight: bold;
}
.submit-script-model,
.submit-script-method {
  color: white;
}
.submit-script-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-radius: 25px;
  color: rgb(27, 44, 77);
  padding: 10px;
  font-size: 16px;
  gap: 5px;
  text-decoration: none;
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 200px;
}
.current-cart-item-text-wrapper {
  display: flex;
  justify-content: center;
  line-height: 10px;
  flex-direction: column;
  height: 200px;
}
.current-cart-item-image {
  border: 2px solid white;
  border-radius: 3px;
}
.expand-img {
  opacity: 0.5;
  background-color: white;
  position: absolute;
  left: 102px;
  cursor: pointer;
}
.current-cart-item-title {
  font-weight: bolder;
  font-size: 20px;
}
.current-cart-item-year {
  color: gray;
  font-weight: lighter;
  font-size: 16px;
}

.links-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.extra-links {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  width: 100%;
  align-self: flex-start;
  gap: 10px;
  padding: 10px 20px;
}
.trademark-right {
  width: 10%;
    background: transparent;
    /* background-color: white; */
}
.book-caption,
.right-arrow {
  color: white;
}
.home-link {
  cursor: pointer;
}
.right-arrow {
  font-size: 20px;
}
.book-caption {
}
.preview-main {
  width: 100%;
  background: #000;
  height: 100vh;
  margin: auto;
}
.image-preview {
  width: 300px;
  height: 500px;
}

.middle-section-one {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 20px;
}
.left-section {
  flex-basis: 60%;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 30px;
}
 {
  width: 100%;
  /* box-shadow: 1px 1px 1px 1px rgb(220, 220, 220); */
  padding: 20px;
  border-radius: 7px;
  /* background-color: #f1f5f7; */
}
.overview-content {
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  font-weight: lighter;
  padding: 20px;
  color: #201e1e;
  background-color: #f1f5f7;
  margin-bottom: 25px !important;
  border-radius: 15px;
}
.editorial-description-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.editorial-description-bold {
  font-weight: bolder;
  font-size: 16px;
}
.editorial-description-light {
  font-weight: bold;
  font-size: 16px;
}
.editorial-description-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.editorial-description-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex-basis: 50%;
  width: 100%;
}
.editorial-description-inner-items {
  display: flex;
  align-items: center;
  gap: 30px;
}
.editorial-description-innner-items-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.editorial-description-inner-icon {
  width: 44px;
  height: 44px;
  border: thin solid gray;
  border-radius: 50%;
  padding: 3px;
  background-color: rgb(216, 243, 246);
}
.editorial-description-inner-light {
  color: #333;
  font-weight: bold;
  font-size: 16px;
}
.editorial-description-inner-bold {
  color: #333;
  font-weight: lighter;
  font-size: 16px;
}
.editorial-medium-card {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding: 24px;
  border-radius: 4px;
}
.editorial-medium-card-left-item {
  width: 100%;
}
.editorial-medium-card-right-item {
  display: flex;
  line-height: 2;
  flex-direction: column;
  width: 100% !important;
}

.editorial-medium-card-right-item-text {
  text-decoration: underline;
  text-align: left;
  padding-right: 2px;
  font-size: 16px;
}
.editorial-medium-card-right-item-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.editorial-medium-card-right-item-icon {
  width: 18px;
}
.editorial-medium-card-right-item-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}
.editorial-medium-card-right-item-container-last {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.editorial-medium-card-bottom-right-item-caption-last-left {
  font-weight: 400;
}
.editorial-medium-card-bottom-right-item-caption-last-middle {
  text-decoration: underline;
  color: rgb(21, 17, 95);
}
.editorial-last-left-cards {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}
.editorial-last-left-card {
  border-radius: 10px;
  background-color: rgb(216, 243, 246);
  padding: 20px;
  border-left: 10px solid rgb(27, 103, 180);
}
.view-script-icon-last-right {
  width: 24px;
  height: 24px;
}
.view-script-btn-last {
  flex-basis: 20%;
  width: 100%;
  border: 3px solid rgb(21, 17, 95);
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 52px;
  min-width: fit-content;
  text-decoration: none;
}
.view-script-last-btn {
  flex-basis: 20%;
  /* width: 100%; */
  border: 3px solid rgb(21, 17, 95);
  border-radius: 30px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 52px;
  min-width: fit-content;
  text-decoration: none;
}
.editorial-medium-card-bottom-right-item-title {
  font-weight: bold;
  font-size: 24px;
  padding: 20px;
}
.editorial-medium-card-bottom-right-item-text {
  font-weight: lighter;
}
.editorial-medium-card-bottom-right-item-text-blue {
  font-weight: bold;
  color: rgb(21, 17, 95);
}
.editorial-medium-card-right-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.view-script-btn {
  width: 100%;
  border: 3px solid rgb(21, 17, 95);
  border-radius: 30px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 52px;
  text-decoration: none;
}
.authors-link {
  margin: 20px 5px;
}

.editors-paragraph {
  font-weight: lighter;
  font-size: 16px;
}
.show-more-icon,
.show-more {
  color: rgb(0, 128, 255);
  font-size: 16px;
}
.show-more {
  font-size: 16px;
  color: rgb(0, 128, 255);
  text-decoration: underline rgb(0, 128, 255);
  cursor: pointer;
}
.description-figure {
}
.description-text {
  font-weight: lighter;
  font-size: 16px;
  color: rgb(181, 174, 174);
}
.contents {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 10px;
  flex-direction: column;
}
.content-item {
  color: rgb(5, 77, 148);
  text-align: left;
  font-weight: lighter;
  font-size: 16px;
}
.editorial-right-section {
  flex-basis: 23%;
  width: 100%;
}
-container {
  line-height: 3;
}
.editors-title,
.editorial-right-section-title {
  font-size: 24px;
  font-weight: bold;
}

.editorial-links-main-container {
  width: 100%;
  /* box-shadow: 1px 2px 2px rgb(220, 220, 220); */
}
.editorial-right-main-container {
  width: 100%;
  /* box-shadow: 1px 2px 2px rgb(220, 220, 220); */
  border-radius: 10px;
  margin-top: 30px;
}
.editorial-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
}
.editorial-bottom-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddebf0;
  width: 100%;
  padding: 15px 5px;
}
.editorial-link {
  color: #333;
  font-size: 16px;
}
.editorial-bottom-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}
.editorial-bottom-content {
  font-size: 16px;
}
.editorial-links-icon {
  background-color: #ddebf0;
  height: 35px;
  width: 34px;
  padding: 5px;
  border-radius: 40%;
}
.editorial-medium-card-bottom-right-item-title-bottom {
  text-align: left;
  font-weight: bolder;
  font-size: 24px;
}
.editorial-medium-card-bottom-right-item-text-bold-bottom {
  font-size: 16px;
  text-decoration: underline;
  font-weight: bold;
}
.editorial-medium-card-right-item-wrapper-bottom {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}
.editorial-medium-card-bottom-right-item-text-light {
  font-weight: 400;
  font-size: 16px;
}
.editorial-medium-card-bottom-right-container {
  display: flex;
  flex-basis: 20%;
  width: 100%;
  padding: 10px;
}
.editorial-medium-card-bottom-right-wrapper {
  display: flex;
  justify-content: center;
  align-self: flex-start;
  gap: 15px;
}
.editorial-medium-card-bottom-right-item-caption-bold {
  font-weight: 400;
}
.editorial-medium-card-bottom-right-item-caption-light {
  font-weight: lighter;
}
.editorial-medium-card-bottom-right-img {
  width: 100%;
  height: 100%;
}
.view-script-icon {
  height: 48px;
  width: 38px;
  padding: 5px;
  border-radius: 40%;
}
.institution-login {
  background-color: rgb(41, 91, 140);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  cursor: pointer;
  border-radius: 2px;
  height: 50px;
}
.institution-text {
  color: white;
  font-weight: lighter;
  font-size: 16px;
}
.institution-arrow {
  color: white;
}
.buy-it-now {
  color: rgb(32, 31, 31);
  padding: 10px;
  font-weight: lighter;
  font-size: 16px;
}
.right-widget-paragraph {
  padding: 10px;
  color: rgb(32, 31, 31);
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
}
.links-container {
  padding: 10px;
}
.link-out-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border: 1px solid gray;
  padding: 5px 10px;
  background-color: rgb(236, 245, 246);
}
.link-out {
  font-size: 16px;
  font-weight: lighter;
}
.icon-out {
  color: rgb(41, 91, 140);
}
.upper-links-wrapper {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid gray;
  padding: 5px 10px;
  background-color: rgb(236, 245, 246);
}
.upper-link-title {
  font-size: 16px;
  font-weight: lighter;
  color: rgb(41, 91, 140);
}
.buy-book-btn {
  width: 100%;
  background-color: rgb(41, 91, 140);
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  padding: 10px 30px;
}
.upper-links-wrapper-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upper-links-item-wrapper {
  background-color: white;
}
.upper-link-cost {
  font-size: 16px;
  color: rgb(8, 78, 147);
}
.upper-links-wrapper-top-isolate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}
.add-to-cart-modal {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 450px;
  margin: 40vh auto;
  height: 150px;
  background-color: white;
  border: thin solid gray;
  padding: 10px;
  /* box-shadow: 2px 1px 2px rgb(220, 220, 220); */
}
.add-to-cart-inner-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.added-to-your-cart {
  color: gray;
  font-size: 16px;
}
.add-to-cart-inner-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  align-self: flex-start;
}
.close-cart-icon,
.close-cart {
  color: gray;
  font-weight: lighter;
  font-size: 16px;
  color: rgba(31, 39, 185, 0.683);
  cursor: pointer;
}
.add-to-cart-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.go-to-checkout-btn {
  background-color: rgb(41, 91, 140);
  color: white;
  flex-basis: 48%;
  width: 100%;
  border-radius: 4px;
  font-weight: lighter;
  font-size: 16px;
}
.go-to-cart-btn {
  color: gray;
  flex-basis: 48%;
  width: 100%;
  border-radius: 4px;
  font-weight: lighter;
  font-size: 16px;
  background-color: white;
}

.go-to-checkout-btn,
.go-to-cart-btn {
  border: thin solid rgb(214, 206, 206);
  padding: 10px 50px;
  text-decoration: none;
}

.editorial-journal-info-section {
  width: 100%;
  margin-top: 30px;
}
.editorial-journal-info-top {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: column;
  width: 40%;
}
.editorial-journal-info-top-last {
  display: flex;
  margin: 40px 0px 40px 0px;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: column;
  width: 40%;
}
.editors-title {
  font-size: 24px;
}
.editorial-journal-info-top-wrapper {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  line-height: 2;
}
.editorial-journal-info-bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.editorial-journal-info-bottom-wrapper {
}
.editorial-journal-info-bottom-caption {
  font-size: 16px;
}
.editors-bottom-title {
  font-weight: bold;
  font-size: 18px;
}
.editors-bottom-title-last {
  font-weight: bold;
  font-size: 16px;
}
.editorial-journal-info-bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  line-height: 1.5;
  width: 100%;
}
.editorial-journal-info-bottom-caption-last {
  font-size: 16px;
  text-decoration: underline;
}

/* Footer */
.editorial-footer {
  width: 100%;
  background-color:  var(--primary-color) !important;
  padding: 25px 10px;
}
.editorial-footer-top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}
.editorial-footer-top-items {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
}
.editorial-footer-top-item {
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.editorial-footer-top-item-title {
  color: white;
  font-weight: 600;
  font-size: 24px;
}
.editorial-footer-top-item-description {
  color: white;
  font-weight: lighter;
  font-size: 16px;
  text-decoration: none !important;
}
.editorial-footer-divider {
  width: 100%;
  height: 2px;
  background-color: white;
  margin-top: 35px;
}
.editorial-footer-top-item-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.editorial-footer-top-item-top-item {
  color: white !important;
  font-weight: lighter;
  font-size: 16px;
}
.editorial-footer-bottom-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 18px;
  margin-top: 30px;
}
.bottom-footer-text {
  color: white;
}
.authenticated {
  display: flex;
  justify-content: space-around;
  width: fit-content;
  align-items: center;
  gap: 10px;
}
/* Test */

.toggle-wrapper{
  color: var(--style-blue-dark);
  outline: none;
  right: 55px;
  top: 20px;
  position: absolute;
  z-index: 101;
  font-size: 24px;
  display: none;
}
.toggle-menu-text {
  color: var(--style-blue-dark);
  outline: none;
  right: 20px;
  top: 20px;
  position: absolute;
  z-index: 101;
  font-size: 24px;
  display: none;
}
@media screen and (max-width: 500px) {
  .cart-header {
    height: 120px;
  }
  .toggle-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 5px !important;
    color: #201e1e;
    width: fit-content;
    position: absolute;
    left: 60px;
    top: -20px;
    cursor: pointer;
  }
  .toggle-menu{
  padding-left: 5px;
  top: 300px;
  font-weight: normal;
  color: #201e1e;
  }
  .toggle-menu-close{
    padding-left: 5px;
    top: 0px;
    left: 60px;
    font-weight: normal;
    color: #201e1e;
    position: absolute;
    z-index: 105;
    }
  .toggle-menu-text{
    font-size: 16px;
    font-weight: normal;
    color: #201e1e;
    left: 25px;
    top: 0px;
    }

  nav {
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list {
    flex-direction: column;
    background: var(--style-blue-dark);
    border: thin solid var(--style-blue-cyan);
    border-radius: 5px;
    height: auto;
    padding: 0 20px;
    top: var(--navbar-height);
    width: 70%;
    left: 0;
    justify-content: flex-start;
    z-index: 400;
  }
  .items:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 50px;
  }
  .items {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    color: var(--style-blue-cyan) !important;
    margin-right: 0px;
    padding: 20px 0;
  }
  .nav-btn {
    color: white;
    left: -50px;
    border: none;
    top: 0px;
    background: transparent;
    position: absolute;
  }
  .toggle-menu-open,.toggle-menu-close,.toggle-menu-text {
    display: block;
    color: #000;
  }
  .cart-link {
    color: rgb(40, 38, 38);
    border-bottom: thick solid #d2e4e7;
    width: 100%;
    font-size: 16px;
    padding: 40px 50px 40px 15px;
    margin: auto;
    overflow-x: hidden;
    background-color: #e5f2f4;
  }
  .cart-items-menu-container {
    z-index: 104;
    padding: 20px 10px 20px 10px;
    flex-basis: 100%;
  }
  .cart-menu-items {
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    background-color: #e5f2f4;
    margin-top: -50px;
    margin-left: -10px;
    gap: 0px;
    z-index: 100;
  }
  .serch-text {
    font-size: 18px;
  }
  .serch-icon {
    font-size: 120px;
  }
  .search-wrapper {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    /* padding-left: -50px; */
    /* gap: 40px; */
    align-items: center;
  }
  .cart-tools {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    align-items: center;
    flex-basis: 30%;
    width: 100%;
    margin: 30px 0px;
    cursor: pointer;
  }
  .items-in-cart-wrapper {
    position: absolute;
    right: 60px;
    top: 0px;
    z-index: 0;
    background-color: #cf0606e1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .items-in-cart {
    color: white;
    z-index: 10;
    font-size: 16px;
    font-weight: lighter;
    text-align: center;
  }
  .cart-auth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}
.editorial-medium-card-bottom-right-item-caption-last-left-wrapper {
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}
.editorial-right-container {
  padding: 5px;
}
@media only screen and (max-width: 500px) {
  .cart-body {
    width: 100%;
  height: 250px;
  flex-direction: column;
  }
  .links-wrapper {
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    margin: 0;
    width: 100vw;
  }
  .editorial-medium-card-bottom-right-item-caption-bold {
    font-weight: 400;
    font-size: 16px;
  }
  .left-current-cart-item-picture{
    position: absolute;
    top: -126px;
    left: -10px !important;
  }
  .home-link {
    font-weight: 100;
    font-size: 16px;
  }
  .right-arrow {
    font-size: 16px;
    font-weight: lighter;
  }
  .book-caption {
    font-size: 16px;
  }
  .left-current-cart-item {
    flex-basis: 100%;
    position: relative;
    margin: auto;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    justify-content: space-between;
  }
  .left-current-cart-item-wrapper {
    flex-basis: 100%;
    width: 100%;
    height: auto;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .right-current-journal-item {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    align-self: flex-end;
    height: auto;
    position: absolute;
    bottom: 10px !important;
    right: 0;
  }
  .submit-script-link-title {
    color: white !important;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: left;
  }
  .submit-script-model,
  .submit-script-method {
    color: white;
    font-size: 16px;
    font-weight: 400;
  }
  .trademark-right {
    width: 25% !important;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .submit-script-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0px !important;
    border-radius: 25px;
    color: rgb(27, 44, 77);
    font-size: 16px;
    gap: 5px;
    padding: 10px;
    margin: auto;
    align-self: flex-start;
    text-decoration: none;
    background-color: white;
    font-weight: bolder;
    position: absolute;
    /* bottom: 50px !important; */
    left: -5px !important;
  }
  .editorial-top-section {
    flex-direction: column;
  }
  .editorial-info-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-direction: column;
  }
  .editorial-info-wrapper {
    background-color: rgb(237, 244, 245);
    width: 100%;
    display: flex;
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .middle-section-one {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    padding: 0px;
    flex-direction: column;
  }
  .editorial-description-inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 20px;
  }
  .editorial-medium-card {
    width: 100%;
    padding: 10x;
    flex-direction: column;
  }
  .editorial-medium-card-right-item-bottom {
    flex-direction: column;
  }
  .editorial-medium-card-right-item-container {
    gap: 20px;
  }
  .editorial-medium-card-right-item-wrapper {
    gap: 10px;
  }
  .editorial-medium-card-bottom-right-item-text {
    font-size: 16px;
  }
  .editorial-medium-card-bottom-right-item-text-blue {
    font-weight: bold;
    font-size: 16px;
    color: rgb(21, 17, 95);
  }
  .editorial-medium-card-right-item-container {
    flex-direction: column;
    gap: 20px;
  }
  .view-script-btn-last {
    flex-basis: 100%;
    width: 100%;
    border: 3px solid rgb(21, 17, 95);
    border-radius: 30px;
    padding: 2px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    min-width: fit-content;
    text-decoration: none;
  }
  .editorial-medium-card-right-item-container-last {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .editorial-journal-info-section {
    margin: auto;
  }
  .editorial-medium-card-bottom-right-item-text-light {
    font-weight: 400;
    font-size: 16px;
  }
  .editorial-journal-info-section {
    width: 100%;
    margin-top: 30px;
    padding: 10px;
  }
  .editorial-journal-info-bottom-caption {
    font-weight: bold;
    font-size: 16px;
  }
  .cart-items-nav-bar {
    flex-direction: column;
  }
  .editors-title,
.editorial-right-section-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
}
  .search-wrapper-inner {
  flex-basis: 90%;
  width: 100%;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  }
  .separator {
   display: none;
  }
  .search-img-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-left: 6px;
    width: fit-content;
  }
}
@media only screen and (max-width:400px) {
  .left-current-cart-item-picture{
    position: absolute;
    top: -155px;
    left: -20px !important;
  }
  .right-current-journal-item {
    width: 260px !important;
    left: -20px;
  }
}
@media only screen and (max-width:250px) {
  .left-current-cart-item-picture{
    position: absolute;
    top: -150px;
    left: -20px !important;
    width: 120px !important;
  }
  .right-current-journal-item {
    width: 200px !important;
  }
  .submit-script-btn {
    left: 0px !important;
    border-radius: 20px;
    color: rgb(27, 44, 77);
    font-size: 16px;
    gap: 5px;
    padding: 5px;
    margin: auto;
    bottom: -20px;
    left: 28px;
  }
}
.editorial-medium-card-right-item-container-wrapper{
  flex-basis: 70%;
  width: 100%;
}
.editorial-medium-card-bottom-right-container{
  flex: 30%;
  width: 100%;
}